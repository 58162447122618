import React, { useState, useEffect } from "react";
import {
  RecurlyProvider,
  Elements,
  CardElement,
  useRecurly
} from "@recurly/react-recurly";
import { Redirect, Link } from "react-router-dom";
import { Card, Button, Form, Col } from "react-bootstrap";
import { db } from "./firebase.jsx";
import Spinner from "react-bootstrap/Spinner";
import {
  ordinal_suffix_of,
  toDatetime,
  toEpochISO,
  mountRecaptcha,
  unMountRecaptcha,
  ManualAccountChange,
  CompanyProfile
} from "./interfaceListShackPro.jsx";

function withRecurlyCheckout(WrappedComponent, props) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        fetchedRecurlyId: false,
        recurlyId: null
      };
      this.handleCheckoutState = this.handleCheckoutState.bind(this);
      this.propogateSubscriptionChange = this.propogateSubscriptionChange.bind(
        this
      );
    }
    instance;

    async propogateSubscriptionChange(props) {
      let { userDoc, handleAlerts, subscription, plan } = props;
      try {
        //let customersRef = db.collection("customers").doc(customerDocId);
        let bca = toEpochISO(subscription.createdAt);
        let planInfo = Object.values(this.props.plans).find(
          plan => plan.planID === subscription.plan.code
        );
        console.log("planInfo: ", planInfo);
        await db
          .collection("users")
          .doc(userDoc.uid)
          .update({
            downloadLimit: plan.downloadLimit,
            leads: plan.leads,
            billing_cycle_anchor: bca,
            leadsRemaining: plan.leadsRemaining || plan.leads,
            includesRollover: plan.includesRollover
          });
        handleAlerts(
          "",
          <React.Fragment>
            You're subscribed to the {plan.shortname} plan. You can download up
            to {plan.leads.toLocaleString()} leads every month. Start searching
            for leads{" "}
            <Link to="/search" className="alert-link">
              here
            </Link>
            .
          </React.Fragment>,
          "success",
          "Payment successful!"
        );
        return;
      } catch (err) {
        console.error("Subscription change did not propogate: ", err);
        handleAlerts(
          "",
          `${err.message ? err.message : ""} Please try again.`,
          "danger",
          "Payment failed"
        );
        props.handleState({
          isSubscribing: false
        });
      }
    }

    async checkTraffic(props) {
      let { apiKey } = props;
      return new Promise(async (resolve, reject) => {
        await window.grecaptcha.ready(async function() {
          await window.grecaptcha
            .execute(process.env.REACT_APP_recaptcha_site_key, {
              action: "signup"
            })
            .then(async function(response) {
              const init = {
                method: "POST",
                headers: {
                  authorization: `Bearer ${apiKey}`,
                  "Content-Type": "application/json"
                },
                "Transfer-Encoding": "chunked",
                cache: "default",
                accept: "application/json",
                body: JSON.stringify({ response })
              };
              let r = await fetch(
                `${process.env.REACT_APP_api_url}/api/v2/verify_traffic`,
                init
              );
              let isValid = await r.json();
              console.log("isValid: ", isValid);
              resolve(isValid);
            });
        });
      });
    }

    async subscribeRecurly(props) {
      //console.log("token: ", token);
      let {
        plan,
        apiKey,
        handleState,
        account,
        handleAlerts,
        handleCheckoutState
      } = props;
      //console.log("plan: ", plan);
      // Set the billing info on the account
      const init = {
        method: "POST",
        headers: {
          authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json"
        },
        "Transfer-Encoding": "chunked",
        cache: "default",
        accept: "application/json",
        body: JSON.stringify({
          plan_code: plan.planID,
          currency: `USD`,
          account: {
            code: account.code
          }
        })
      };
      try {
        // Update account billing information
        //let accountResponse = await fetch(`${process.env.REACT_APP_api_url}/api/recurly/accounts/update/${this.state.recurlyId}`, init);
        //let account = await accountResponse.json();
        //console.log("Updated account.");

        let response = await fetch(
          `${process.env.REACT_APP_api_url}/api/recurly/subscriptions/create`,
          init
        );
        let subscription = await response.json();
        //console.log("subscription: ", subscription);
        if (subscription.transactionError) {
          throw subscription.transactionError;
        }
        await this.propogateSubscriptionChange({
          ...props,
          subscription,
          plan
        });
        handleState({
          displayModal: false,
          account: null
        });
        handleCheckoutState({ fetchedRecurlyId: false });
        props.setRecurlyAccount(props);
        console.log("Subscribed.");
        return subscription;
      } catch (err) {
        console.error("Something went wrong subscribing to Recurly: ", err);
        handleAlerts(
          "",
          `${err.message ? err.message : ""} Please try again.`,
          "danger",
          "Payment failed"
        );
        handleState({
          isSubscribing: false,
          retrySubscribe: true
        });
      }
    }

    async cancelRecurlySubscription(props) {
      let { apiKey, customerDoc } = props;
      if (
        window.confirm(
          `Wait! If you cancel now you'll lose access immediately. You can wait to cancel until the ${ordinal_suffix_of(
            toDatetime(customerDoc.billing_cycle_anchor).getDate() - 1
          )} without being billed again. Are you sure you'd like to cancel your subscription?`
        )
      ) {
        console.log("Yes, I want to cancel");
        const init = {
          method: "POST",
          headers: {
            authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json"
          },
          "Transfer-Encoding": "chunked",
          cache: "default",
          accept: "application/json"
        };
        let subId = customerDoc.subscription_id
          ? customerDoc.subscription_id
          : `uuid-${customerDoc.subscription_uuid}`;
        try {
          let response = await fetch(
            `${process.env.REACT_APP_api_url}/api/recurly/subscriptions/terminate/${subId}`,
            init
          );
          let subscription = await response.json();
          if (subscription.type === "validation") {
            throw subscription;
          }
          if (subscription.transactionError) {
            throw subscription.transactionError;
          }
          console.log("Subscription successfully cancelled!");
          props.handleAlerts(
            "",
            "Your subscription was canceled. Sorry to see you go and hope to see you back again soon!",
            "success"
          );
          props.handleCheckoutState({ fetchedRecurlyId: false });
          props.setRecurlyAccount(props);
          return subscription;
        } catch (error) {
          console.error("The subscription was not cancelled.", error);
          props.handleAlerts(
            "",
            `Uh oh, something went wrong.  Please send an email to ${process.env.REACT_APP_contact_email} to cancel your subscription.`,
            "warning"
          );
        }
      } else {
        console.log("No, I do not want to cancel");
      }
    }

    async changePaymentMethod(props) {
      let { apiKey, handleAlerts, token, handleState, account } = props;
      handleState({ isSubscribing: true });
      try {
        const init = {
          method: "POST",
          headers: {
            authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json"
          },
          "Transfer-Encoding": "chunked",
          cache: "default",
          accept: "application/json",
          body: JSON.stringify({
            billing_info: {
              token_id: token.id
            }
          })
        };
        let response = await fetch(
          `${process.env.REACT_APP_api_url}/api/recurly/accounts/update/${account.id}`,
          init
        );
        let newAccount = await response.json();
        if (newAccount.transactionError) {
          throw newAccount.transactionError;
        }
        console.log("Changed payment method.");
        return newAccount;
      } catch (err) {
        console.log("Error changing payment method: ", err);
        handleAlerts("", err.message, "warning");
        handleState({ isSubscribing: false });
        return account;
      }
    }

    async changeSubscription(props) {
      const { apiKey, customerDoc, plan, handleAlerts, handleState } = props;
      let subId = customerDoc.subscription_id
        ? customerDoc.subscription_id
        : customerDoc.subscription_uuid
        ? `uuid-${props.customerDoc.subscription_uuid}`
        : null;
      if (!subId) {
        handleAlerts(
          "",
          <React.Fragment>
            Uh oh, you don't currently have a subscription.{" "}
            <Link to="/signup">Sign up here instead</Link>
          </React.Fragment>,
          "warning",
          "Bummer :("
        );
        return handleState({ displayModal: false });
      }
      if (
        window.confirm(
          `Are you sure you'd like to change your subscription to the ${plan.shortname} plan? The card on file will be immediately billed.`
        )
      ) {
        handleState({ isSubscribing: true });
        const init = {
          method: "POST",
          headers: {
            authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json"
          },
          "Transfer-Encoding": "chunked",
          cache: "default",
          accept: "application/json",
          body: JSON.stringify({
            timeframe: "now",
            plan_code: plan.planID
          })
        };
        try {
          let response = await fetch(
            `${process.env.REACT_APP_api_url}/api/recurly/subscriptions/createChange/${subId}`,
            init
          );
          let subscriptionChange = await response.json();
          if (subscriptionChange.transactionError) {
            throw subscriptionChange.transactionError;
          }
          console.log("Changed subscription.");
          await this.propogateSubscriptionChange({
            ...props,
            subscription: subscriptionChange,
            plan
          });
          handleState({
            displayModal: false,
            isSubscribing: false
          });
        } catch (err) {
          console.error(
            "Something went wrong changing the subscription: ",
            err
          );
          handleAlerts(
            "",
            `${err.message ? err.message : ""} Please try again.`,
            "danger",
            "Payment failed"
          );
          handleState({
            isSubscribing: false
          });
        }
      }
    }

    async findCustomer(props) {
      let { apiKey, email } = props;

      const init = {
        method: "POST",
        headers: {
          authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json"
        },
        "Transfer-Encoding": "chunked",
        cache: "default",
        accept: "application/json",
        body: JSON.stringify({ email })
      };
      try {
        let response = await fetch(
          `${process.env.REACT_APP_api_url}/api/recurly/accounts/find/${email}`,
          init
        );
        let customers = await response.json();
        console.log("Found account.");
        return customers;
      } catch (err) {
        console.error("Problem finding customer: ", err);
        return [];
      }
    }

    async createCustomer(props) {
      let { user, plan, apiKey } = this.props;
      let customersRef = props.customerDoc ? props.customerDoc._docRef : db.collection("customers").doc(this.props.user.uid);
      let customerDocId = user.uid;
      let code = customerDocId;
      let email = user.email;
      try {
        const init = {
          method: "POST",
          headers: {
            authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json"
          },
          "Transfer-Encoding": "chunked",
          cache: "default",
          accept: "application/json",
          body: JSON.stringify({ email, code })
        };
        let response = await fetch(
          `${process.env.REACT_APP_api_url}/api/recurly/accounts/create`,
          init
        );
        let account = await response.json();
        console.log("Created account.");

        await customersRef.set({
          billing_cycle_anchor: Math.round(new Date().getTime() / 1000),
          subscription_status: "canceled",
          customer_id: account.id,
          plan_id: plan.planID,
          email: user.email,
          uid: customerDocId,
          canceled_at: null,
          sub_checked: 0,
          processor: "recurly"
        });
        return account;
      } catch (err) {
        console.log("Error creating customer: ", err);
      }
    }

    async getClientToken(clientId) {
      // Get a client token for authorization from your server
      let { apiKey } = props;
      const init = {
        method: "GET",
        headers: {
          authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json"
        },
        "Transfer-Encoding": "chunked",
        cache: "default",
        accept: "application/json"
      };
      let response = await fetch(
        `${process.env.REACT_APP_api_url}/api/v2/client_token/${clientId}`,
        init
      );
      let clientToken = await response.json(); // If returned as JSON string

      return await clientToken;
    }

    async setRecurlyAccount(props) {
      let { user, userDoc } = props;
      // Wait for the server to write the userDoc before we create a customerDoc
      if (
        userDoc !== null &&
        userDoc !== undefined &&
        !props.fetchedRecurlyId
      ) {
        console.log("Setting Recurly Account!");
        this.handleCheckoutState({ fetchedRecurlyId: true });
        let recurlyId = null;
        let recurlyAccount;
        let currentCustomers = await this.findCustomer({
          ...props,
          email: user.email
        });
        if (currentCustomers.length < 1) {
          console.log("Creating a new account on Recurly.");
          recurlyAccount = await this.createCustomer({
            ...props,
            email: user.email
          });
          console.log("recurlyAccount: ", recurlyAccount);
          recurlyId = recurlyAccount.id;
        } else {
          console.log(
            "Email address is already associated with a Recurly customer."
          );
          recurlyId = currentCustomers[0]["id"];
          recurlyAccount = currentCustomers[0];
        }
        if (recurlyId !== null && props.recurlyId !== recurlyId) {
          this.handleCheckoutState({
            recurlyId,
            recurlyAccount
          });
          return props.handleState({ account: recurlyAccount });
        }
      }
    }

    handleRecurlyErrors(err) {
      let errMap = {
        number: "Card number",
        month: "Expiration month",
        year: "Expiration year",
        first_name: "First name",
        last_name: "Last name"
      };

      let errDetails = [];
      if (err.details) {
        err.details.map(detail => {
          console.log("detail: ", detail);
          return detail.messages.map(message => {
            return errDetails.push(
              <li>{`${errMap[detail.field]} ${message}`}</li>
            );
          });
        });
      }
      // Don't use the global alert for now, just leave it inline.
      //let alertMessage = (
      //  <React.Fragment>
      //    {err.message}
      //    {errDetails.length &&
      //      <ul>
      //        {errDetails}
      //      </ul>
      //    }
      //  </React.Fragment>
      //)
      //this.props.handleAlerts("", alertMessage, "warning");
    }

    handleCheckoutState(obj) {
      this.setState(obj);
    }

    render() {
      //console.log("recurlyCheckout this.props: ", this.props, "recurlyCheckout this.state: ", this.state);
      const { haveActiveSub } = this.props;

      let path = window.location.pathname;

      if (!this.state.recurlyId) {
        return (
          <div>
            <h5>Loading...</h5>
          </div>
        );
      } else {
        if (haveActiveSub && path === "/signup") {
          const urlParams = new URLSearchParams(window.location.search);
          const next = urlParams.get("next") || "/settings/billing";
          return <Redirect to={next} />;
        }

        return (
          <WrappedComponent
            {...this.state}
            {...this.props}
            propogateSubscriptionChange={this.propogateSubscriptionChange}
            propogateChangeSubsription={this.propogateChangeSubscription}
            checkTraffic={this.checkTraffic}
            subscribeRecurly={this.subscribeRecurly}
            cancelRecurlySubscription={this.cancelRecurlySubscription}
            changePaymentMethod={this.changePaymentMethod}
            changeSubscription={this.changeSubscription}
            findCustomer={this.findCustomer}
            createCustomer={this.createCustomer}
            getClientToken={this.getClientToken}
            setRecurlyAccount={this.setRecurlyAccount}
            handleRecurlyErrors={this.handleRecurlyErrors}
            handleCheckoutState={this.handleCheckoutState}
          />
        );
      }
    }

    async componentDidMount() {
      //console.log("Mounting recurlyCheckout!");
      if (
        !this.props.account
          ? true
          : !this.props.customerDoc
          ? true
          : this.props.account.email !== this.props.customerDoc.email
      ) {
        this.setRecurlyAccount({ ...this.state, ...this.props });
      } else {
        this.setState({
          recurlyAccount: this.props.account,
          recurlyId: this.props.account.id
        });
      }
      mountRecaptcha();
    }

    async componentDidUpdate() {
      //console.log("Updating recurlyCheckout!");
      this.setRecurlyAccount({ ...this.state, ...this.props });
    }

    componentWillUnmount() {
      unMountRecaptcha();
    }
  };
}

const PaymentMethodCard = props => {
  const { account, allowChange, handleCheckoutState } = props;
  //console.log("recurlyAccount: ", account);
  if (!account || !account.billingInfo || !account.billingInfo.paymentMethod) {
    return null;
  }
  let { firstName, lastName } = account.billingInfo;
  let {
    firstSix,
    lastFour,
    expMonth,
    expYear,
    cardType
  } = account.billingInfo.paymentMethod;

  return (
    <React.Fragment>
      <h6>Payment method</h6>
      <Card className="bg-light">
        <Card.Body
          className="d-flex flex-column justify-content-between"
          style={{ minHeight: "200px" }}
        >
          <div className="d-flex flex-row justify-content-between align-items-center">
            <span style={{ fontSize: "1.4em", textTransform: "uppercase" }}>
              {cardType}
            </span>
            {allowChange && (
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => {
                  handleCheckoutState();
                }}
              >
                {typeof account.billingInfo.paymentMethod !== "undefined"
                  ? "Change"
                  : "Add another"}
              </Button>
            )}
          </div>
          <br />
          <div className="mb-0 d-flex flex-row justify-content-between align-items-center">
            <div>
              <span>
                <strong>
                  {" "}
                  {firstSix}******{lastFour}{" "}
                </strong>
              </span>
              <br />
              <span style={{ textTransform: "capitalize" }}>
                {firstName} {lastName}
              </span>
            </div>
            <small className="text-muted">
              Valid:{" "}
              <i>
                {expMonth}/{expYear}
              </i>
            </small>
          </div>
          <p className="text-muted mb-0">
            <small>
              This card is used for your monthly subscription payment.
            </small>
          </p>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

const CardForm = props => {
  //console.log("CardForm props: ", props);
  const [errors, setErrors] = useState([]);
  const { errorCallback, tokenCallback } = props;
  const formRef = React.useRef();
  const recurly = useRecurly();
  function fetchToken(e) {
    e.preventDefault();
    recurly.token(formRef.current, (err, token) => {
      if (err) {
        console.log("Error fetching recurlyToken: ", err);
        if (err.code === "validation") {
          setErrors(err.details);
        }
        if (errorCallback) {
          errorCallback(err);
        }
        errorCallback(err);
      }
      if (token) {
        console.log("Recurly token: ", token);
        //formRef.current.reset();
        if (tokenCallback) {
          tokenCallback(token);
        }
      }
    });
  }

  function findError(errField) {
    return errors.find(error => error.field === errField);
  }

  console.log(
    "errors: ",
    errors,
    errors.find(error => error.field === "first_name"),
    typeof errors.find(error => error.field === "first_name") !== "undefined"
      ? "First name can't be blank"
      : ""
  );

  return (
    <Form
      onSubmit={e => fetchToken(e)}
      className={props.className ? props.className : ""}
      style={props.style ? props.style : {}}
      ref={formRef}
      validated={errors.length ? true : false}
      noValidate
    >
      <Form.Label>Payment Method</Form.Label>
      <Form.Row>
        <Form.Group as={Col} controlId="first_name" className="mb-0">
          <Form.Control
            type="text"
            data-recurly="first_name"
            placeholder="First name"
            isInvalid={findError("first_name") ? true : false}
            required
          />
          {findError("first_name") && (
            <Form.Control.Feedback type="invalid">
              First name can't be blank
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} controlId="last_name" className="mb-0">
          <Form.Control
            type="text"
            data-recurly="last_name"
            placeholder="Last name"
            isInvalid={findError("last_name") ? true : false}
            required
          />
          {findError("last_name") && (
            <Form.Control.Feedback type="invalid">
              Last name can't be blank
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Form.Row>
      <Form.Row className="mt-2">
        <Form.Group as={Col} controlId="first_name" className="mb-0">
          <Form.Control
            type="text"
            data-recurly="address1"
            placeholder="Street address"
            isInvalid={findError("address1") ? true : false}
            required
          />
          {findError("first_name") && (
            <Form.Control.Feedback type="invalid">
              Street address can't be blank
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="postal_code" className="mb-0">
          <Form.Control
            type="text"
            data-recurly="city"
            placeholder="City"
            className="mt-2"
            isInvalid={findError("city") ? true : false}
            required
          />
          {findError("city") && (
            <Form.Control.Feedback type="invalid">
              City cannot be blank
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} controlId="postal_code" className="mb-0">
          <Form.Control
            type="text"
            data-recurly="state"
            placeholder="State"
            className="mt-2"
            isInvalid={findError("state") ? true : false}
            required
          />
          {findError("state") && (
            <Form.Control.Feedback type="invalid">
              State cannot be blank
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} controlId="postal_code" className="mb-0">
          <Form.Control
            type="text"
            data-recurly="postal_code"
            placeholder="Zip Code"
            className="mt-2"
            isInvalid={findError("postal_code") ? true : false}
            required
          />
          {findError("postal_code") && (
            <Form.Control.Feedback type="invalid">
              Zip code cannot be blank
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Form.Row>
      <CardElement onSubmit={e => fetchToken(e)} />
      <div className="d-flex flex-row justify-content-between">
        {props.submitButton && props.submitButton}
        {props.cancelButton && props.cancelButton}
      </div>
    </Form>
  );
};

const ManageRecurlyPaymentMethod = props => {
  const { showCard, showForm } = props;
  const [displayForm, setDisplayForm] = useState(null);
  const [displayCard, setDisplayCard] = useState(null);

  useEffect(() => {
    if (typeof showForm !== "undefined") {
      //setDisplayCard(!showForm);
      setDisplayForm(showForm);
    }
    if (typeof showCard !== "undefined") {
      //setDisplayForm(!showCard)
      setDisplayCard(showCard);
    }
  }, [showCard, showForm]);

  if (displayForm) {
    return (
      <RecurlyProvider publicKey={process.env.REACT_APP_recurly_public_key}>
        <Elements>
          <CardForm
            {...props}
            errorCallback={err => props.handleRecurlyErrors(err)}
            tokenCallback={async token => {
              if (await props.checkTraffic(props)) {
                props.handleState({ isSubscribing: true });
                let newAccount = await props.changePaymentMethod({
                  ...props,
                  token
                });
                props.handleState({
                  account: newAccount,
                  isSubscribing: false
                });
                setDisplayForm(false);
                setDisplayCard(true);
                if (props.dropinCallback) {
                  props.dropinCallback(props);
                }
              }
            }}
            submitButton={
              <div className="mt-3">
                <Button
                  variant="success"
                  type="submit"
                  disabled={props.isSubscribing}
                >
                  {props.account.billingInfo
                    ? typeof props.account.billingInfo.paymentMethod !==
                      "undefined"
                      ? "Save new payment method "
                      : "Add payment method "
                    : "Add payment method "}
                  {props.isSubscribing && (
                    <Spinner
                      animation="grow"
                      variant="primary"
                      size="sm"
                      as="span"
                      role="status"
                    />
                  )}
                </Button>
              </div>
            }
            cancelButton={
              <Button
                variant="link"
                className="text-muted mt-3"
                onClick={() => {
                  setDisplayForm(false);
                }}
              >
                Nevermind
              </Button>
            }
          />
        </Elements>
      </RecurlyProvider>
    );
  }
  if (displayCard) {
    return (
      <PaymentMethodCard
        {...props}
        handleCheckoutState={() => {
          setDisplayForm(!displayForm);
          //setDisplayCard(!displayCard)
        }}
      />
    );
  }
  return null;
};

const ManageAccount = props => {
  return (
    <div style={{ width: "400px" }}>
      <AccountStatus {...props} />
      <SubscriptionCard {...props} plan={props.userPlan} />
      <ManageRecurlyPaymentMethod
        {...props}
        allowChange={true}
        className="mb-4 mt-3"
        recurlyFields={["first_name", "last_name", "postal_code"]}
        showCard={
          props.account ? (props.account.billingInfo ? true : false) : false
        }
      />
      <CancelSubscription {...props} />
    </div>
  );
};

const ManageRecurlyAccount = withRecurlyCheckout(ManageAccount);

const Blank = props => {
  return (
    <div className="lead">One Sec, grabbing your billing information.</div>
  );
};

const Subscribe = props => {
  let { byPassVerification, account, plan, isSubscribe, manualSubscribe } = props;
  let billingInfo;
  console.log("Renderinging Subscribe: ", props);
  if (account) {
    let showSubscribe = !account.hasLiveSubscription;
    let showUpgrade = account.hasLiveSubscription && isSubscribe !== true;
    let showLoading = account.hasLiveSubscription && isSubscribe === true;
    billingInfo = account.billingInfo ? account.billingInfo : null;
    //console.log(
    //  "Subscribe props: ",
    //  props,
    //  "showSubscribe: ",
    //  showSubscribe,
    //  "showUpgrade: ",
    //  showUpgrade,
    //  "showLoading: ",
    //  showLoading
    //);
    let isVerified = byPassVerification ? byPassVerification : false;
    return (
      <div style={{ width: "400px" }}>
        {!isVerified && (
          props.onboardingComponent
        )}
        {isVerified && (
          <React.Fragment>
            <SubscriptionCard {...props} />
            <ManageRecurlyPaymentMethod
              {...props}
              allowChange={billingInfo}
              className="mb-4"
              recurlyFields={["first_name", "last_name", "postal_code"]}
              showCard={!account ? false : account.billingInfo ? true : false}
              showForm={!account ? true : account.billingInfo ? false : true}
            />
            {showSubscribe && (
              <Button
                variant="success"
                className="mt-5 mb-5"
                onClick={async e => {
                  e.preventDefault();
                  if (await props.checkTraffic(props)) {
                    props.subscribeRecurly(props);
                  }
                }}
                disabled={!account ? true : !billingInfo}
                block
              >
                Subscribe to {plan.shortname} plan
              </Button>
            )}
            {showUpgrade && (
              <Button
                variant="success"
                className="mt-5 mb-5"
                onClick={async e => {
                  e.preventDefault();
                  if (await props.checkTraffic(props)) {
                    props.changeSubscription(props);
                  }
                }}
                disabled={!billingInfo}
                block
              >
                Change to {plan.shortname} plan
              </Button>
            )}
            {showLoading && (
              <Button
                variant="success"
                className="mt-5 mb-5"
                disabled={true}
                block
              >
                <span>Updating to {plan.shortname} plan</span>{" "}
                <Spinner animation="grow" variant="light" as="span" size="sm" />
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
  if (!account) {
    return <Blank {...props} />;
  }
};

const SubscribeRecurly = withRecurlyCheckout(Subscribe);

const AccountStatus = props => {
  //console.log("AccountStatus: ", props);
  const { isCancelled, isPastdue, isCancelling, isFreeTrial, bd } = props;
  return (
    <div>
      <Form.Group style={{ marginBottom: "50px" }}>
        {isCancelled && (
          <p className="lead text-danger">
            Your subscription is no longer active
          </p>
        )}
        {isPastdue && (
          <p className="text-danger">
            We've been unable to bill your card on file. Please update your card
            below to avoid your subscription being canceled for non-payment.
          </p>
        )}
        {!isCancelling && !isCancelled && !isFreeTrial && (
          <p>
            Your monthly subscription payment is charged on the{" "}
            {ordinal_suffix_of(bd)} of each month.
          </p>
        )}
        {isCancelling && !isCancelled && (
          <p className="lead text-danger">
            Your subscription is cancelled. Your account access will be revoked
            on the {() => ordinal_suffix_of(bd)}.
          </p>
        )}
      </Form.Group>
    </div>
  );
};

const SubscriptionCard = props => {
  console.log("SubscriptionCard props: ", props);
  const {
    plan,
    handleState,
    isSubscribing,
    isPastDue,
    isSubscribe,
    isCancelled,
    subscriptionCardTitle
  } = props;
  if (isCancelled && !props.displayModal && !isSubscribe) {
    return (
      <React.Fragment>
        <Button
          className="mb-3 mt-2"
          onClick={() => props.handleState({ displayModal: true })}
        >
          Subscribe now
        </Button>
      </React.Fragment>
    );
  }

  return (
    <Form.Group style={{ marginTop: "40px", maxWidth: "400px" }}>
      { subscriptionCardTitle
        ? subscriptionCardTitle
        : <Form.Label>Subscription plan</Form.Label>
      }
      <Card
        className="bg-light"
        style={{ marginBottom: "30px", minHeight: "250px" }}
      >
        <Card.Body className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <span style={{ fontSize: "1.2em" }}>{plan["shortname"]} plan</span>
            <Button
              onClick={() => {
                props.selectPlanCallback
                  ? props.selectPlanCallback()
                  : props.displayModal
                    ? handleState({ showComparePlans: true })
                    : handleState({ displayModal: true });
              }}
              disabled={isSubscribing || isPastDue}
              variant="outline-primary"
              size="sm"
            >
              Change
            </Button>
          </div>
          <p className="mb-0">
            <i>{plan.description}</i>
          </p>
          <br />
          <div>
            <p className="mb-0">
              <span>
                <strong>${plan["price"] / 100} per month.</strong> Cancel
                anytime.
              </span>
            </p>
            <p className="text-muted mb-0">
              <small>This is the monthly payment amount.</small>
            </p>
          </div>
        </Card.Body>
      </Card>
    </Form.Group>
  );
};

const CancelSubscription = props => {
  const { haveActiveSub, isCancelled, isFreeTrial, isPastDue } = props;
  if (haveActiveSub && ((!isCancelled && !isFreeTrial) || isPastDue)) {
    return (
      <React.Fragment>
        <hr className="mt-5" />
        <Form.Group>
          <Button
            variant="danger"
            onClick={e => {
              e.preventDefault();
              props.cancelRecurlySubscription(props);
            }}
          >
            Cancel subscription
          </Button>
        </Form.Group>
      </React.Fragment>
    );
  }
  return null;
};

export { SubscribeRecurly, ManageRecurlyAccount, SubscriptionCard };
