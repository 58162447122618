import React from "react";
import ReactDOM from "react-dom";
import AppFreeor from "./AppFreeor.jsx";
import freeorPlans from "./freeorPlans.jsx";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from "./serviceWorker";
//import firebaseApp from './firebase.jsx';
import "font-awesome/css/font-awesome.min.css";

ReactDOM.render(
  <AppFreeor
    plans={freeorPlans}
    orgs={["BYU JRCLS Fellowship", "BYU Hawaii"]}
  />,
  document.getElementById("root")
);

serviceWorker.unregister();
