const freeorPlans = {
  0: {
    shortname: "Free",
    description: "Create one course and up to 3 lessons for FREE.",
    name: "Free",
    price: 0,
    planID: process.env.REACT_APP_plan_freeor_free.toLowerCase(),
    courses: 1,
    lessons: 3,
    users: 0,
    public: true
  },
  1: {
    shortname: "Basic",
    description: "Create three courses with up to 10 lessons each.",
    name: "Basic",
    price: 5000,
    planID: process.env.REACT_APP_plan_freeor_basic.toLowerCase(),
    courses: 3,
    lessons: 10,
    users: 0,
    public: true
  },
  2: {
    shortname: "Pro",
    description: "Create 10 courses with up to 20 lessons each. Add three additional users to your account.",
    name: "Pro",
    price: 9900,
    planID: process.env.REACT_APP_plan_freeor_pro.toLowerCase(),
    courses: 10,
    lessons: 20,
    users: 3,
    public: true
  },
  3: {
    shortname: "Biz",
    description: "Up to 100 courses of 50 lessons each.  Add your whole staff.",
    name: "Business",
    price: 49900,
    planID: process.env.REACT_APP_plan_freeor_biz.toLowerCase(),
    courses: 100,
    lessons: 50,
    users: 25,
    public: true
  }
};

export default freeorPlans;
