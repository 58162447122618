import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Link, Redirect, useParams } from "react-router-dom";

import { Container, Row, Col, Button, Nav, Tab } from "react-bootstrap";
import {
  CompanyProfile,
  TeamMembers,
  UsageTable,
  NotAdmin
} from "./interfaceListShackPro.jsx";
import { ManageRecurlyAccount } from "./recurlyCheckoutAgain.jsx";
import ChangePass from "./changePassword.jsx";
import Invoices from "./invoices.jsx";
import UpdateEmail from "./updateEmail.jsx";

const SettingsRouter = props => {
  useEffect(() => {
    props.handleState({
      isApp: true,
      noDistract: true
    });
  }, []);

  return (
    <Switch>
      <Route exact path="/settings/:id">
        <SettingPane {...props} />
      </Route>
      <Route exact path="/settings">
        <Redirect to="/settings/profile" />
      </Route>
    </Switch>
  );
}

export default SettingsRouter;

const SettingPane = props => {
  //console.log("SettingPane props: ", props);
  let {panes} = props;
  let { id } = useParams();
  //console.log("id: ", id);
  const { role, userDoc, user } = props;
  const isAdmin = role === "admin";

  if (!user) {
    return <Redirect to={`/login?next=/settings/${id}`} />;
  }

  //console.log("customerDoc.plan_id.toLowerCase(): ", customerDoc.plan_id.toLowerCase(),"userPlan: ", userPlan);
  let pane = panes.find(p => p.id === id);

  return (
    <React.Fragment>
      <Helmet>
        <title>{pane.name}</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      <Container>
        <Row style={{ marginTop: "30px" }}>
          <Col style={{ minHeight: "80vh" }}>
            <div className="d-flex justify-content-between">
              <h1>Settings</h1>
              {userDoc.companyProfile !== undefined && (
                <div className="text-right">
                  <p className="lead">{userDoc.companyProfile.name}</p>
                  <p>
                    Logged-in as: <code>{user.email}</code> <br />
                    Account id: <code>{userDoc.id}</code>
                  </p>
                </div>
              )}
              {userDoc.userProfile !== undefined && (
                <div className="text-right">
                  <p className="lead">{userDoc.userProfile.name}</p>
                  <p>
                    Logged-in as: <code>{user.email}</code> <br />
                    Account id: <code>{userDoc.id}</code>
                  </p>
                </div>
              )}
            </div>
            <hr />
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    {panes.map(pane => {
                      //console.log("isAdmin: ", isAdmin);
                      let navItem = (
                        <Nav.Item key={pane.id}>
                          <Link
                            className={`nav-link ${
                              id === pane.id ? "active" : ""
                            }`}
                            eventkey={pane.eventKey}
                            to={`/settings/${pane.id}`}
                          >
                            {pane.name}
                          </Link>
                        </Nav.Item>
                      );
                      if (isAdmin) {
                        return navItem;
                      } else if (!pane.admin) {
                        return navItem;
                      }
                      return null;
                    })}
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    {pane.content({ ...props, isAdmin })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
        {/*<Switch>
          <Route path={`${url}/:id`}>
            <SettingPane />
          </Route>
        </Switch>
        */}
      </Container>
    </React.Fragment>
  );
}

const panes = [
  {
    id: "profile",
    name: "Company Profile",
    admin: true,
    activeKey: "sixth",
    content: props => {
      const {
        userDoc,
        apiKey,
        user,
        handleAlerts,
        isCancelled,
        isAdmin,
        handleModalShow
      } = props;
      return (
        <Tab.Pane eventKey="first">
          {!isCancelled && isAdmin && (
            <CompanyProfile
              heading={<h3 className="mt-3">Company profile</h3>}
              userDoc={userDoc}
              handleAlerts={handleAlerts}
              user={user}
              apiKey={apiKey}
            />
          )}
          {isCancelled && isAdmin && (
            <div className="mt-3">
              <h3>Company profile</h3>
              <p className="lead text-danger">
                Your subscription is no longer active. Please update your
                subscription to view and edit your company profile.
              </p>
              <Button onClick={handleModalShow}>Subscribe now</Button>
            </div>
          )}
          {!isAdmin && <NotAdmin {...props} />}
        </Tab.Pane>
      );
    }
  },
  {
    id: "team",
    name: "Team members",
    admin: true,
    activeKey: "seventh",
    content: props => {
      const {
        userDoc,
        apiKey,
        handleAlerts,
        handleModalShow,
        userPlan,
        isCancelled,
        isAdmin
      } = props;
      return (
        <Tab.Pane eventKey="first">
          {!isCancelled && isAdmin && (
            <TeamMembers
              heading={
                <div className="mt-3">
                  <h3>Team members</h3>
                  <p className="lead text-muted">
                    Add colleagues and employees
                  </p>
                </div>
              }
              userDoc={userDoc}
              handleAlerts={handleAlerts}
              plan={userPlan}
              handleModalShow={handleModalShow}
              apiKey={apiKey}
            />
          )}
          {isCancelled && isAdmin && (
            <div className="mt-3">
              <h3>Team members</h3>
              <p className="lead text-muted">Add colleagues and employees</p>
              <p className="lead text-danger">
                Your subscription is no longer active. Please update your
                subscription to view and edit team members.
              </p>
              <Button onClick={handleModalShow}>Subscribe now</Button>
            </div>
          )}
          {!isAdmin && <NotAdmin {...props} />}
        </Tab.Pane>
      );
    }
  },
  {
    id: "billing",
    name: "Billing & Payment",
    admin: true,
    activeKey: "third",
    content: props => {
      //console.log("Billing props: ", props );
      const { isAdmin } = props;
      return (
        <Tab.Pane eventKey="first">
          {isAdmin && (
            <React.Fragment>
              <h3>Billing &amp; payment</h3>
              <ManageRecurlyAccount {...props} />
            </React.Fragment>
          )}
          {!isAdmin && <NotAdmin {...props} />}
        </Tab.Pane>
      );
    }
  },
  {
    id: "invoices",
    name: "Invoices",
    activeKey: "fifth",
    admin: true,
    content: props => {
      return (
        <Tab.Pane eventKey="first">
          {props.isAdmin && <Invoices {...props} />}
          {!props.isAdmin && <NotAdmin {...props} />}
        </Tab.Pane>
      );
    }
  },
  {
    id: "usage",
    name: "Usage",
    activeKey: "second",
    content: props => {
      const { isCancelled, handleModalShow } = props;
      return (
        <Tab.Pane eventKey="first">
          <h3>Usage</h3>
          {!isCancelled && <UsageTable {...props} />}
          {isCancelled && (
            <React.Fragment>
              <p className="lead text-danger">
                Your subscription is no longer active. Update your susbscription
                to view account usage information.
              </p>
              <Button onClick={handleModalShow}>Subscribe now</Button>
            </React.Fragment>
          )}
        </Tab.Pane>
      );
    }
  },
  {
    id: "update_email",
    name: "Update email",
    activeKey: "fourth",
    content: props => {
      return (
        <Tab.Pane eventKey="first">
          <UpdateEmail {...props} />
        </Tab.Pane>
      );
    }
  },
  {
    id: "change_password",
    name: "Change password",
    activeKey: "first",
    content: props => {
      const { user, handleAlerts } = props;
      return (
        <Tab.Pane eventKey="first">
          <ChangePass user={user} handleAlerts={handleAlerts} />
        </Tab.Pane>
      );
    }
  }
];
