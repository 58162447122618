import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Home, LessonRouter, Courses, Quizzes, QuizRouter, GetInvolved, AboutUs, ReadingList, OrgAdmin, ShareLessons, RecommendLecturer, HowItWorks } from "./interfaceFreeor.jsx";
import RequireRedirect from "./requireRedirect.jsx"
const FreeorRoutes = props => {
  let {loaded, superAdmin} = props;
  return(
    <React.Fragment>
      <Route exact path="/">
        <Helmet>
          <title>FreerFirst.org</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Home {...props} />
      </Route>

      <Route exact path="/home">
        <Helmet>
          <title>FreerFirst.org</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Home {...props} />
      </Route>

      <Route path="/contact">
        <Helmet>
          <title>Contact Us</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <div>Contact page goes here.</div>
      </Route>

      <Route path="/reading-list">
        <Helmet>
          <title>Reading List</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ReadingList />
      </Route>

      <Route path="/faq">
        <Helmet>
          <title>Frequently Asked Questions</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <div>FAQs page goes here.</div>
      </Route>

      <Route path="/about">
        <Helmet>
          <title>About</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <AboutUs />
      </Route>

      <Route path="/how-it-works">
        <Helmet>
          <title>How It Works</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <HowItWorks />
      </Route>

      <Route path="/get-involved">
        <Helmet>
          <title>Get Involved</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <GetInvolved />
      </Route>

      <Route path="/terms">
        <Helmet>
          <title>Terms of service</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <div>Terms page goes here</div>
      </Route>

      <Route path="/privacy">
        <Helmet>
          <title>Privacy policy</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <div>Privacy page goes here.</div>
      </Route>

      <Route path="/support">
        <Helmet>
          <title>Support Center</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <div>Support page goes here.</div>
      </Route>

      <Route path="/recommend-lecturer">
        <Helmet>
          <title>Recommend a Lecturer</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <RecommendLecturer {...props} />
      </Route>

      <Route path="/share-lessons">
        <Helmet>
          <title>Share Lessons</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ShareLessons {...props} />
      </Route>

      {loaded &&
        <React.Fragment>
          {superAdmin &&
            <Route path="/courses">
              <Helmet>
                <title>Courses</title>
                <meta name="HandheldFriendly" content="true" />
                <meta name="apple-mobile-web-app-capable" content="YES" />
              </Helmet>
              <RequireRedirect {...props} >
                <Courses {...props} />
              </RequireRedirect>
            </Route>
          }
          <Route path="/course">
            <Helmet>
              <title>Lesson</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <RequireRedirect {...props} >
              <LessonRouter
                {...props}
              />
            </RequireRedirect>
          </Route>

          <Route path="/quizzes">
            <Helmet>
              <title>Quizzes</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <RequireRedirect {...props} >
              <Quizzes {...props} />
            </RequireRedirect>
          </Route>

          <Route path="/quiz">
            <Helmet>
              <title>Quiz</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <RequireRedirect {...props} >
              <QuizRouter
                {...props}
              />
            </RequireRedirect>
          </Route>
          <Route path="/org">
            <Helmet>
              <title>Org admin</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <RequireRedirect {...props} >
              <OrgAdmin
                {...props}
              />
            </RequireRedirect>
          </Route>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default FreeorRoutes;
