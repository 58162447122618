import React, {useState, useEffect} from "react";
import {Redirect} from "react-router-dom";

const RequireRedirect = props => {
  const {userDoc} = props;
  const [redirect,setRedirect] = useState(false);

  useEffect( () => {
    if (userDoc) {
      if (userDoc.verified === false) {
        setRedirect("/signup");
      }
    }

  }, [userDoc]);

  if (redirect) {
    return(
      <Redirect to={redirect} />
    )
  }
  return(
    props.children
  )
}

export default RequireRedirect;
