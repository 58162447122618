import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import withApp from "./withApp.jsx";
import ScrollToTop from "./scrollToTop.jsx";
import { Container, Row, Col, Form } from "react-bootstrap";
import SharedRoutes from "./sharedRoutes.jsx";
import FreeorRoutes from "./freeorRoutes.jsx";
import { TopNav, Footer, FooterSmall, SideBar, settingsPanes, FofBrand, UserNav } from "./interfaceFreeor.jsx";
import AppAlerts from "./appAlerts.jsx";
import { UserProfile } from "./userProfile.jsx";

const AppFreeor = props => {
  const {noDistract, isFreeTrial, isApp} = props;
  const next = window.location.pathname ? window.location.pathname : process.env.REACT_APP_redirect_path;
  //console.log("AppDigest props: ", props);

  return(
    <Router>
      <ScrollToTop>
        {true &&
          <TopNav {...props} next={next} />
        }
        <Container id="freeor" fluid>
          <Row noGutters={true} style={{minHeight: "100vh"}} className="flex-column">
            {false && (
              <Col xs={12}>
                <FofBrand />
                <UserNav {...props} className="pull-right" />
              </Col>
            )}
            <Col>
              <AppAlerts
                {...props}
                hideFreeTrial={true}
                requireUserVerification={true}
              />
              <FreeorRoutes {...props} next={next} />
              <SharedRoutes
                {...props}
                panes={settingsPanes}
                manualSubscribe={true}
                manualSubscribeComponent={
                  <UserProfile
                    {...props}
                    heading={
                      <React.Fragment>
                        <h6 className="mt-3">Create your account profile </h6>
                        <Form.Text className="mb-3">
                          Please create and verify your account information.
                        </Form.Text>
                      </React.Fragment>
                    }
                    isCreate={true}
                    size="md"
                    nameText="This will be displayed to your organization."
                    phoneText="We'll use this to make sure you are a real person and verify your account."
                    emailText="We'll use this to contact you via email and verify your account."
                    addInfo={["org"]}
                    requireUserOrg
                  />
                }
              />
            </Col>
          </Row>
        </Container>
        {/*!noDistract && !isApp && <Footer {...props} />}
        {!noDistract && isApp && <FooterSmall /> */}
      </ScrollToTop>
    </Router>
  )
}

export default withApp(AppFreeor);
