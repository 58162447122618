import React, { useState, useEffect, useRef } from "react";
import {
  Switch,
  Route,
  useParams,
  Link,
  Redirect,
  NavLink,
} from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Button,
  Card,
  Modal,
  Form,
  Image,
  ButtonGroup,
  ProgressBar,
  Tab,
  ListGroup,
  InputGroup,
  Alert,
  Table,
  Badge,
} from "react-bootstrap";
import logo from "./media/logo_freerfirst_800.png";
import border_tl from "./media/border_tl.png";
import border_tr from "./media/border_tr.png";
import border_br from "./media/border_br.png";
import border_bl from "./media/border_bl.png";
import quality from "./media/quality_128.jpg";

import { Collection, CollectionContext } from "./firebaseView.jsx";
import { db, storeMedia, archiveDoc, getDoc } from "./firebase.jsx";
import UpdateEmail from "./updateEmail.jsx";
import ChangePass from "./changePassword.jsx";
import {
  TrelloBoardList,
  createBoard,
  TrelloBoardHeader,
} from "./likeTrello.jsx";
import { UserProfile } from "./userProfile.jsx";
import {
  BookmarkStarFill,
  BookmarkPlus,
  CheckSquareFill,
  DashSquareFill,
  Download,
  CloudUpload,
  Trash,
  NodePlus,
  Triangle,
  XSquare,
  Circle,
  CheckCircleFill,
  CircleFill,
  ChevronLeft,
  DashCircleFill,
  PlusCircleFill,
  Plus,
  Pencil,
  ArrowBarDown,
  ArrowClockwise,
  ArrowRight,
  ArrowLeft,
  BoxArrowUpRight,
  Journal,
  CardText,
  PlayCircleFill,
  Slash,
  Check2,
} from "react-bootstrap-icons";
import ReactDOM from "react-dom";
import MDEditor from "@uiw/react-md-editor";
import * as jsPDF from "jspdf";
import { formatPhoneNumber } from "./interfaceListShackPro.jsx";
// STATIC CONTENT

const Home = (props) => {
  const { handleState } = props;
  useEffect(() => {
    handleState({
      noDistract: false,
      isApp: false,
    });
  }, []);

  return (
    <div className="container">
      <main id="home">
        <section id="home" style={{ textAlign: "center" }}>
          <Container style={{ marginTop: "30px" }}>
            <div className="home-img-row">
              {["meditate.jpg", "boy.jpg", "bow.jpg"].map((ref) => {
                return (
                  <div
                    key={`home_img_${ref}`}
                    style={{ padding: "2px" }}
                    className="home-img-item"
                  >
                    <img
                      src={`./images/home/${ref}`}
                      width="100%"
                      height="100%"
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  </div>
                );
              })}
            </div>

            <div className="home-img-row center">
              {[
                <img
                  src="./images/home/girl.jpg"
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: "cover",
                  }}
                />,

                <div
                  className="d-flex flex-row justify-content-center align-items-center"
                  style={{
                    fontSize: "2vw",
                    fontFamily: "'Times', serif",
                    verticalAlign: "center",
                    height: "100%",
                    border: "solid 1px lightgray",
                  }}
                >
                  <span>
                    The <b>freedom</b> to live out faith is one thing that{" "}
                    <b>unites</b> us all.
                  </span>
                </div>,
                <img
                  src="./images/home/boys.jpg"
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: "cover",
                  }}
                />,
              ].map((ref, i) => {
                return (
                  <div
                    key={`home_prim_img_${i}`}
                    style={{ padding: "2px" }}
                    className="home-img-item"
                  >
                    {ref}
                  </div>
                );
              })}
            </div>

            <div className="home-img-row">
              {["point.jpg", "nun.jpg", "nurse.jpg"].map((ref) => {
                return (
                  <div
                    key={`home_img_row_${ref}`}
                    style={{ padding: "2px" }}
                    className="home-img-item"
                  >
                    <img
                      src={`./images/home/${ref}`}
                      width="100%"
                      height="100%"
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  </div>
                );
              })}
            </div>

            <Row>
              <Col>
                <div style={{ paddingBottom: "100px" }}>
                  <Link
                    to={process.env.REACT_APP_redirect_path}
                    className="btn btn-lg btn-info mt-5 box-shadow text-white"
                  >
                    Dive into the lessons
                    <br />
                    to learn more <ArrowRight size={20} />
                  </Link>
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                className="text-center"
                style={{ paddingBottom: "50px" }}
              >
                <h3>Our Mission</h3>
                <p className="lead">
                  Freer First strives to help students—and others just getting
                  their feet wet in the area of religious liberty—increase their
                  understanding of their freedom to live out their faith.
                  Through our online curriculum, Freer First gives you a
                  flexible and reliable resource powered by religious liberty
                  experts. We’re also here to help you find meaningful ways to
                  get involved in protecting and shaping the future of our First
                  Ammendment freedoms.
                </p>
              </Col>
              <Col xs={12} className="mt-5">
                <h3>How we accomplish our mission</h3>
                <p>
                  We partner with Universities, Private and Public Schools,
                  Fellowships, Professors, and Continuing Education programs to
                  provide our online curriculum to their students. Each partner
                  receives a unique login that allows their organization and
                  students to take our curriculum, add their own curriculum,
                  tailor the quizzes, and track the progress of their students.
                </p>
                <p>
                  In this way, Freer First becomes a fantastic way to supplement
                  or enhance a course or exisiting curriculum. This approach
                  allows your organization to expose your students to experts
                  and curriculum that would otherwise be inaccessible. Beyond
                  this complimentary intro course, we are happy to facilitate
                  the creation of more in depth courses in the future. Please
                  reach out if you'd like more information.
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                className="text-center mt-5"
                style={{ paddingBottom: "200px" }}
              >
                <hr />
                <h4>Want to help us build or share our curriculum?</h4>
                <Link
                  className="btn btn-lg btn-info m-3"
                  to="/recommend-lecturer"
                >
                  I can recommend a lecturer
                </Link>
                <Link className="btn btn-lg btn-info m-3" to="/share-lessons">
                  Share the lessons with my organization
                </Link>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </div>
  );
};

const FofBrand = (props) => {
  return (
    <Link to="/">
      <div
        style={{ width: "100%", backgroundColor: "#000", height: "25px" }}
        className="d-flex flex-row justify-content-between pl-5 pr-5 mt-4"
      >
        {[
          "islam.png",
          "jewish.png",
          "cross.png",
          "sikhism.png",
          "christian.png",
          "atom.png",
          "buddhism.png",
          "om.png",
          "bahai.png",
          "yin-yang.png",
        ].map((ref, i) => {
          return (
            <div
              key={`img_${ref}_${i}`}
              style={{ height: "17px", marginTop: "-2px" }}
            >
              <img
                src={`/images/rbar/${ref}`}
                alt={ref.split(".")[0]}
                height="100%"
                width="100%"
                className=""
                style={{ objectFit: "contain" }}
              />
            </div>
          );
        })}
      </div>
    </Link>
  );
};

const gimages = [
  {
    img: "./images/involved/becket.png",
    alt: "becket",
    desc: "The Becket Fund offers undergrad internships, law student internships, constitutional law fellowships, and government affairs fellowships.",
    link: "https://www.becketlaw.org/about-us/employment/careers/#",
  },
  {
    img: "./images/involved/jrcls.png",
    alt: "j reuben clark law society",
    desc: "JRCLS offers a three-day Religious Liberty Fellowship held each October in D.C.",
    link: "https://www.iclrs.org/wp-content/uploads/2020/07/2020-JRCLS-Religious-Liberty-Fellowship-Flier-and-Application-COVID-19-Update.pdf?fbclid=IwAR1fAllNz6nhD5eq4t0QstQk9sonCSHf61eoPm-Fmb8tfVkN24UiTy6_Fac",
  },
  {
    img: "./images/involved/firstliberty.png",
    alt: "first liberty institute",
    desc: "First Liberty Institute offers a one-year Judicial Fellowship designed for attorneys who have completed federal judicial clerkships and are looking for experience in First Amendment advocacy.",
    link: "https://firstliberty.org/about-us/careers/",
  },
  {
    img: "./images/involved/blf.jpg",
    alt: "blackstone legal fellowship",
    desc: "This is a Christian-focused summer-long program powered by Alliance Defending Freedom. It starts with two weeks of lectures and discussions followed by an internship and ends with trainings. Note: a statement of faith is required to participate.",
    link: "http://www.blackstonelegalfellowship.org/",
  },
  {
    img: "./images/involved/interfaith.png",
    alt: "interfaith alliance",
    desc: "Interfaith Alliance offers both an undergraduate and policy internship.",
    link: "https://interfaithalliance.org/about-us/jobs-and-internships/intern-opportunities/",
  },
  {
    img: "./images/involved/rfi.png",
    alt: "religious freedom institute",
    desc: "RFI offers internships for both undergraduate and graduate student.",
    link: "https://www.religiousfreedominstitute.org/internship",
  },
  {
    img: "./images/involved/bjc.jpg",
    alt: "baptist joint committee",
    desc: "The Baptist Joint Committee for Religious Liberty offers internships for both undergraduate and graduate student.",
    link: "https://bjconline.org/internships/",
  },
];

const details = [
  {
    title: "Law Schools with Religous Liberty Clinics",
    items: [
      {
        name: "Brigham Young University",
        points: [
          {
            text: "International Center for Law and Religion Studies",
            link: "https://www.iclrs.org",
            categories: ["law students"],
          },
          {
            text: "Supreme Court Clinic and Litigating and Legislating Religious Freedom courses by Professor Gene Schaerr",
            link: "https://law.byu.edu/faculty/gene-schaerr/",
            categories: ["law students"],
          },
        ],
      },
      {
        name: "University of Texas",
        points: [
          {
            text: "Law and Religion Clinic with Professor Steven Collis",
            link: "https://law.utexas.edu/clinics/law-and-religion/",
            categories: ["law students"],
          },
        ],
      },
      {
        name: "University of Notre Dame",
        points: [
          {
            text: "Religious Liberty Clinic with Professor Stephanie Barclay",
            link: "https://law.nd.edu/religious-liberty/",
            categories: ["law students"],
          },
        ],
      },
      {
        name: "Stanford University",
        points: [
          {
            text: "Religious Liberty Clinic with Professor James A. Sonne",
            link: "https://law.stanford.edu/religious-liberty-clinic/",
            categories: ["law students"],
          },
        ],
      },
      {
        name: "Harvard University",
        points: [
          {
            text: "Religious Freedom Clinic with Professor Joshua C. McDaniel",
            link: "https://hls.harvard.edu/dept/clinical/religious-freedom-clinic/",
            categories: ["law students"],
          },
        ],
      },
    ],
  },
  {
    title: "Fellowships",
    items: [
      {
        name: "Undergraduates and law students",
        points: [
          {
            text: "JRCLS Religious Liberty Fellowship (all faiths welcome)",
            link: "https://jrcls.org/events/jrcls-religious-liberty-fellowship",
            categories: ["undergraduates", "law students"],
          },
        ],
      },
      {
        name: "Law students",
        points: [
          {
            text: "Blackstone Legal Fellowship (requires statement of faith)",
            link: "http://www.blackstonelegalfellowship.org",
            categories: ["law students"],
          },
        ],
      },
      {
        name: "Law graduates",
        points: [
          {
            text: "Becket Constitutional Law Fellow",
            link: " https://www.becketlaw.org/constitutional-law-fellow-posting/",
            categories: ["law graduates"],
          },
          {
            text: "First Liberty Judicial Fellowship",
            link: "https://firstliberty.org/about-us/careers/",
            categories: ["law graduates"],
          },
        ],
      },
    ],
  },
  {
    title: "Internships",
    items: [
      {
        name: "Undergraduates",
        points: [
          {
            text: "Becket Undergraduate Internship",
            link: "https://www.becketlaw.org/about-us/employment/undergraduate-internships/",
            categories: ["undergraduates"],
          },
          {
            text: "Interfaith Undergraduate Internship",
            link: "https://interfaithalliance.org/about-us/jobs-and-internships/intern-opportunities/",
            categories: ["undergraduates"],
          },
        ],
      },
      {
        name: "Law students",
        points: [
          {
            text: "Becket Legal Internship",
            link: "https://www.becketlaw.org/about-us/employment/internships/",
            categories: ["law students"],
          },
          {
            text: "Interfaith Alliance Policy Internship",
            link: "https://interfaithalliance.org/about-us/jobs-and-internships/intern-opportunities/",
            categories: ["law students"],
          },
        ],
      },
      {
        name: "Undergraduate and graduate students",
        points: [
          {
            text: "Student Liaison at Religious Liberties Practice Group of Federalist Society",
            link: "https://fedsoc.org/practice-groups/religious-liberties-practice-group",
            categories: ["undergraduates", "law students", "graduates"],
          },
          {
            text: "Religious Freedom Institute Internship",
            link: "https://www.religiousfreedominstitute.org/internship",
            categories: ["undergraduates", "law students", "graduates"],
          },
          {
            text: "Baptist Joint Committee for Religious Liberty",
            link: "https://bjconline.org/internships/",
            categories: ["undergraduates", "law students", "graduates"],
          },
        ],
      },
    ],
  },
];

const bios = [
  {
    type: "lecturer",
    order: 1,
    name: "Professor Douglas Laycock",
    title:
      "Professor of Law at University of Virginia and University of Texas at Austin Schools of Law",
    img: "./images/bios/laycock.jpg",
    bio: (
      <React.Fragment>
        <p>
          Professor Laycock is perhaps the nation’s leading authority on the law
          of religious liberty and also on the law of remedies. He has taught
          and written about these topics for more than four decades at the
          University of Chicago, the University of Texas, the University of
          Michigan and now Virginia. Laycock has testified frequently before
          Congress and has argued many cases in the courts, including the U.S.
          Supreme Court, where he has served as lead counsel in six cases and
          has also filed influential amicus briefs. He is the author (co-author
          in the most recent edition) of the leading casebook Modern American
          Remedies, the award-winning monograph{" "}
          <i>The Death of the Irreparable Injury Rule</i> and many articles in
          leading law reviews. He co-edited a collection of essays,{" "}
          <i>Same-Sex Marriage and Religious Liberty</i>.
        </p>
        <p>
          Laycock resigned from the Council and as first vice president of the
          American Law Institute to become co-reporter for the Restatement
          (Third) of Torts: Remedies. He is a fellow of the American Academy of
          Arts and Sciences. He earned his B.A. from Michigan State University
          and his J.D. from the University of Chicago.
        </p>
      </React.Fragment>
    ),
  },
  {
    type: "lecturer",
    order: 4,
    name: "Professor Steven Collis",
    title:
      "Founding faculty director of the Bech-Loughlin First Amendment Center and of Texas's Law and Religion Clinic",
    img: "./images/bios/collis.jpg",
    bio: (
      <React.Fragment>
        <p>
          Professor Collis researches and teaches on religion and law and other
          First Amendment topics. He is the founding faculty director of the
          Bech-Loughlin First Amendment Center and of Texas's Law & Religion
          Clinic. Prior to joining Texas, Steven was the Olin-Darling Research
          Fellow in the Constitutional Law Center at Stanford Law School.
        </p>
         
        <p>
          On the topic of religious freedom law, he is a sought-after speaker to
          academic and lay audiences across the United States, including foreign
          diplomats from countries in Europe, the Middle East, Asia, and South
          America on behalf of the United States State Department. He has been
          interviewed by and quoted in various news and media outlets, including{" "}
          <i>The Deseret News</i>, Bloomberg, <i>The Washington Times</i>,
          Law360, <i>The Salt Lake Tribune</i>, PBS,{" "}
          <i>The Denver Business Journal</i>, <i>Law Week Colorado</i>, CBN
          News, and numerous podcasts and television shows. His scholarly work
          has been cited by justices of the Supreme Court and has appeared in{" "}
          <i>The Michigan Law Review</i>, <i>The Nebraska Law Review</i>,{" "}
          <i>The University of Denver Law Review Online</i>. His books include
          Deep Conviction and The Immortals.
        </p>
        <p>
          Collis serves as the program committee chair for the AALS Law and
          Religion Section and the chair of the JRCLS International Religious
          Freedom Committee. 
        </p>
        <p>
          Earlier in his career, he was an equity partner at Holland & Hart LLP,
          where he chaired the firm’s nationwide religious institutions and
          First Amendment practice group and was a member of the firm's complex
          civil litigation and employment practice groups. He has appeared
          before or practiced in multiple federal appellate courts, many state
          courts, and the United States Supreme Court. He also taught religious
          liberty law at the University of Denver Sturm College of Law and
          clerked for Chief Judge Timothy M. Tymkovich of the United States
          Court of Appeals for the Tenth Circuit.
        </p>
        <p>
          Collis graduated magna cum laude from the University of Michigan Law
          School, where he was elected to the Order of the Coif and served as an
          editor on <i>The Michigan Law Review</i> and{" "}
          <i>The Michigan Journal of Race and Law</i>. He also holds an M.F.A.
          in creative writing from Virginia Commonwealth University, where he
          served as the associate editor of the literary journal{" "}
          <i>Blackbird</i>. He completed his undergraduate studies, with
          university honors, at Brigham Young University. 
        </p>
      </React.Fragment>
    ),
  },
  {
    type: "lecturer",
    order: 5,
    name: "Professor Engy Abdelkader",
    title:
      "Fellow at the Public Religion Research Institute and German Marshall Fund of the United States and professor in the Political Science department at Rutgers",
    img: "./images/bios/abdelkader.jpg",
    bio: (
      <React.Fragment>
        <p>
          Professor Engy Abdelkader is a fellow at the Public Religion Research
          Institute and German Marshall Fund of the United States. Her teaching
          and research explore religion, race, and gender at the intersection of
          law, politics, and society. Representative courses include{" "}
          <i>Racism</i>, <i>Xenophobia and Related Intolerance</i>, <i>Islam</i>
          , <i>Women and Development</i>, <i>Counterterrorism and Democracy</i>,{" "}
          <i>Religion and Politics</i>,{" "}
          <i>
            International Women's Rights, Transitional Justice, and
            Constitutionalism
          </i>
          , among other graduate and undergraduate offerings. Additionally, her
          scholarly publications include, <i>When Islamophobia Turns Violent</i>{" "}
          (Georgetown), <i>A Comparative Analysis of European Islamophobia</i>{" "}
          (UCLA Law), <i>Muslim Americans</i> (Berkeley Law),{" "}
          <i>Immigration in the Era of Trump</i> (NYU Law),{" "}
          <i>
            A Comparative Analysis of Islamic Jurisprudential Approaches to
            Female Judges in the Muslim World
          </i>{" "}
          (Fordham Law), <i>China's Repression of Uigher Muslims</i> (UCLA Law),{" "}
          <i>Muslims and Islam in US Public Schools</i> (UC Hastings  Law) and
          the <i>Rohingya Muslims in Myanmar</i> (Oregon Review of International
          Law).
        </p>
         
        <p>
          Further, her popular writing has appeared on CNN, HuffPost, TIME, the
          Christian Science Monitor, and numerous other outlets. Also dedicated
          to public service, Abdelkader has collaborated with the United
          Nations, U.S. State Department, Organization for Security and
          Cooperation in Europe, and the American Bar Association, among others.
          She holds two U.S. law degrees including credentials from the
          University of Pennsylvania Law School where she served as a
          Constitutional Law Teaching Fellow and graduated with the highest
          honors.
        </p>
      </React.Fragment>
    ),
  },
  {
    type: "lecturer",
    order: 2,
    name: "Asma Uddin",
    title:
      "Director of strategy for the Center for Islam and Religious Freedom",
    img: "./images/bios/uddin.jpg",
    bio: (
      <React.Fragment>
        <p>
          Asma Uddin is a religious liberty lawyer and scholar working for the
          protection of religious expression for people of all faiths in the
          U.S. and abroad. Her areas of expertise include law and religion
          (church/state relations), international human rights law on religious
          freedom, and Islam and religious freedom. Uddin is an active lecturer
          to diverse religious groups in the U.S. and overseas on the importance
          of religious liberty, and she is widely published on the topic by law
          reviews, university presses, and national and international
          newspapers. 
        </p>
        <p>
          Uddin has worked on religious liberty cases at the U.S. Supreme Court,
          federal appellate courts, and federal trial courts. She has defended
          religious claimants as diverse as Evangelicals, Sikhs, Muslims, Native
          Americans, Jews, Catholics, and members of the Nation of Islam. Her
          legal, academic, and policy work focuses on freedom of expression such
          as religious garb, land use, access to religious materials in prison,
          rights of parochial schools, religious arbitration, etc. Uddin worked
          with the U.S. Department of State on advocacy against the UN
          Defamation of Religions Resolution. She received a State Department
          grant to develop the Legal Training Institute in the Middle East and
          North Africa and Southeast Asian countries.
        </p>
        <p>
          In addition to her legal work, Uddin writes and speaks on Muslims and
          gender. As the founding editor-in-chief of{" "}
          <a
            href="https://www.altmuslimah.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            altmuslimah.com
          </a>
          , she has managed the web-magazine and organized vigorous debates and
          conferences on the multifaceted issues of gender, politics, and
          religion. Uddin has advised numerous media projects on American
          Muslims, including most recently as Executive Producer for the Emmy
          and Peabody nominated docu-series, The Secret Life of Muslims.
        </p>
        <p>
          After graduating from The University of Chicago Law School, Uddin
          served as Counsel for the Becket Fund for Religious Liberty and as
          Director of Strategy for the Center for Islam and Religious Freedom in
          Washington, D.C. She is an expert advisor on religious liberty to the
          Organization for Security and Cooperation in Europe (OSCE), Senior
          Scholar at the Newseum’s Religious Freedom Center, a Visiting Scholar
          at Brigham Young University Law school, and a non-residential fellow
          at UCLA and Georgetown University. She is also a term-member with the
          Council on Foreign Relations and an adjunct law professor at George
          Mason University Law School.
        </p>
      </React.Fragment>
    ),
  },
  {
    type: "lecturer",
    order: 6,
    name: "Suhag Shukla",
    title: "Executive Director and co-founder of Hindu American Foundation",
    img: "./images/bios/shukla.jpg",
    bio: (
      <React.Fragment>
        <p>
          Suhag Shukla is Executive Director and co-founder of Hindu American
          Foundation. She holds a BA in Religion and JD from the University of
          Florida. Ms. Shukla has helped steer the Foundation to being
          recognized as a leading voice for civil rights, human rights, and
          religious freedom. She's been instrumental in the development of a
          broad range of educational materials and position papers and blogs for
          a variety of platforms. Ms. Shukla has served on the Boards of the
          Nirvana Center, Main Line Indian Association, and YWCA of Minneapolis.
          She was also a member of the Department of Homeland Security
          Faith-Based Security and Communications Subcommittee.
        </p>
        <p>
          Shukla is actively involved with Chinmaya Mission, serves on the board
          of the Bhutanese American Organization of Philadelphia, and is a
          thought partner for the Interfaith Center of Greater Philadelphia's
          Paths to Understanding Public Art Initiative. Shukla is a member of
          the board for the National Museum of American Religion and serves on
          religious advisory committees for the Council on Foreign Relations,
          and the Humane Society of the United States, and is a member of the
          First Amendment Center's Committee on Religious Liberty and the United
          Nations Women's Gender Equality and Religion platform. Shukla was
          named one of twelve “faith leaders to watch in 2017” by the Center for
          American Progress.
        </p>
      </React.Fragment>
    ),
  },
  {
    type: "lecturer",
    order: 3,
    name: "Professor Stephanie Inks",
    title: "Research and clinical fellow at Georgetown University Law Center",
    img: "./images/bios/inks.jpg",
    bio: (
      <React.Fragment>
        <p>
          Professor Inks is a research and clinical fellow at Georgetown
          University Law Center. Previously, she practiced law for six years as
          a litigator at Simpson, Thacher, and Bartlett in both New York and
          Washington D.C. where she represented a wide variety of clients
          including corporations, financial institutions, and individuals in a
          broad range of complex disputes including securities litigation,
          insurance coverage litigation, general commercial litigation, as well
          as antitrust, corporate and government investigations and white-collar
          proceedings. On a pro-bono basis, she represented two indigent
          children from Ecuador, winning them a grant of asylum. Before
          beginning her practice, Inks taught Constitutional Law to
          undergraduate students at Louisiana State University.
        </p>
        <p>
          Her area of interest includes teaching law students with an emphasis
          on how to engage and promote dialogue in a civil, respectful, mutually
          understanding way while living with the deepest of differences in a
          pluralistic society. Her most recent article: “How Immigration Law’s
          Looming RFRA Problem Can Be Solved By RFRA” was published by BYU Law
          Review in 2019.
        </p>
        <p>
          Inks received her J.D. from Georgetown University Law Center and her
          B.A. magna cum laude from Hillsdale College.
        </p>
      </React.Fragment>
    ),
  },
  {
    type: "lecturer",
    order: 10,
    name: "Professor Jim Sonne",
    title:
      "Professor of Law at Stanford University, Founding Direct of Stanford Religious Liberty Clinic",
    img: "./images/bios/sonne.jpg",
    bio: (
      <React.Fragment>
        <p>
          Jim Sonne is a professor of law at Stanford Law School and founding
          director of its Religious Liberty Clinic, the leading and only
          full-time academic program in the country where students can learn the
          practice and profession of law through supervised litigation in that
          field. He is an experienced and award-winning teacher, practitioner,
          and scholar, with particular expertise in law and religion issues.
        </p>
        <p>
          Professor Sonne earned his BA with honors from Duke University and his
          JD with honors from Harvard Law School. He is a former law clerk to
          Judge Edith Brown Clement of ­­the U.S. Court of Appeals for the Fifth
          Circuit. Sonne also previously served as an associate professor of law
          at Ave Maria School of Law in Ann Arbor, Michigan; as an appellate
          lawyer at Horvitz & Levy in Los Angeles; and as a labor and employment
          lawyer at McGuireWoods in Richmond, Virginia. He has also served as a
          visiting professor of law at Harvard Law School.
        </p>
        <p>
          Professor Sonne has published full-length articles in the Clinical Law
          Review, Notre Dame Law Review, and Georgia Law Review, among others,
          as well as shorter pieces in the Harvard Law Review, Oxford Journal of
          Church and State, and New England Journal of Medicine. His work has
          also been featured by the New York Times, Wall Street Journal,
          National Law Journal, and California Lawyer.
        </p>
      </React.Fragment>
    ),
  },
  {
    type: "staff",
    order: 100,
    name: "Cate Grantham",
    title: (
      <React.Fragment>
        Founder &amp; Curriculum Development
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </React.Fragment>
    ),
    img: "./images/bios/cate.jpg",
    bio: (
      <React.Fragment>
        <p>
          Cate Grantham is a third year law student at BYU. She has focused her
          studies and work on religious liberty, including assisting Professor
          Stephanie Barclay with her research, helping to draft amicus briefs
          for Our Lady of Guadalupe School and The Little Sisters of the Poor,
          and interning with PLF, the Becket Fund, Justice Thomas Rex Lee, and
          Schaerr-Jaffe. Prior to law school, she graduated from BYU with a B.A.
          in political science. She grew up in Orange County and enjoys skiing
          (especially moguls), popcorn (especially brown butter lemon), and
          tennis (especially doubles).
        </p>
      </React.Fragment>
    ),
  },
  {
    type: "staff",
    order: 100,
    name: "Anna Bryner",
    title: (
      <React.Fragment>
        Founder &amp; Communications
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </React.Fragment>
    ),
    img: "./images/bios/bryner.jpg",
    bio: (
      <React.Fragment>
        <p>
          Anna Bryner graduated from Brigham Young University in 2021 with a
          bachelor's degree in communications and a minor in political science.
          During her undergraduate experience, Anna worked at BYU Law School's
          International Center for Law and Religion Studies, co-founded BYU's{" "}
          <a href="https://byuforb.wordpress.com" target="_blank">
            Freedom of Religion or Belief Club
          </a>
          , and wrote{" "}
          <a
            href="https://www.deseret.com/opinion/2021/7/19/22579124/what-religious-freedoms-fo-you-actually-have-supreme-court-smith-test-jonas-yoder"
            target="_blank"
          >
            several articles and op-eds on religious freedom
          </a>
          . She also served a mission for The Church of Jesus Christ of
          Latter-day Saints in upstate New York from 2017-2019. A native of
          Price, Utah, Anna likes to spend her free time hiking, singing, and
          cheering for BYU football.
        </p>
      </React.Fragment>
    ),
  },
  {
    type: "staff",
    order: 100,
    name: "Wyatt Grantham",
    title: (
      <React.Fragment>
        Founder &amp; Technology Development
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </React.Fragment>
    ),
    img: "./images/bios/grantham.png",
    bio: (
      <React.Fragment>
        <p>
          Wyatt Grantham loves software and technology. He began his career
          working in the Aerospace industry at Raytheon Company and helped start
          a Cybersecurity training product line. He has since helped start
          several technology startups including{" "}
          <a href="https://analyzecorp.com/" target="_blank">
            Analyze Corporation
          </a>{" "}
          and{" "}
          <a href="https://sonlet.com/" target="_blank">
            Sonlet.com
          </a>
          . Wyatt earned his Bachelors degree from Brigham Young University.
        </p>
      </React.Fragment>
    ),
  },
];

const readingList = [
  {
    author: "Kevin Seamus Hasson",
    title: "Right to Be Wrong",
    type: "Books",
  },
  {
    author: "Luke Goodrich",
    title: "Free to Believe: The Battle Over Religious Liberty",
    type: "Books",
  },
  {
    author: "Kevin Seamus Hasson",
    title: "Believers, Thinkers, and Founders",
    type: "Books",
  },
  {
    author: "Steven Collis",
    title: "Deep Conviction",
    type: "Books",
  },
  {
    author: "Michael W. McConnell & Nathan S. Chapman",
    title:
      "Michael W. McConnell & Nathan S. Chapman, Agreeing to Disagree: How the Establishment Clause Protects Religious Diversity and Freedom of Conscience",
    year: 2023,
    link: "https://www.amazon.com/Agreeing-Disagree-Establishment-Religious-Conscience/dp/B0C7X5JS3K/ref=sr_1_1?crid=2BDLUA9KGOIYK&keywords=agreeing+to+disagree+book+nathan+chapman&qid=1698376146&sprefix=agreeing+to+disagree%2Caps%2C165&sr=8-1#customerReviews%5D",
    type: "Books",
  },
  {
    author: "Luke Goodrich",
    title: "Free to Believe: The Battle Over Religious Liberty",
    yeaer: 2019,
    link: "  https://www.amazon.com/Free-to-Believe-Luke-Goodrich-audiobook/dp/B07YNV6QHL/ref=sr_1_1?crid=3B9MF5CQE0JFZ&keywords=Luke+Goodrich%2C+Free+to+Believe%3A+The+Battle+Over+Religious+Liberty&qid=1698377334&s=books&sprefix=luke+goodrich%2C+free+to+believe+the+battle+over+religious+liberty%2Cstripbooks%2C182&sr=1-1",
    type: "Books",
  },
  {
    author: "Kevin Seamus Hasson",
    title: "Right to Be Wrong",
    year: 2012,
    link: "https://www.amazon.com/Right-Be-Wrong-Culture-Religion/dp/0307718107",
    type: "Books",
  },
  {
    author: "Steven Collis",
    title: "Deep Conviction",
    year: 2019,
    link: "https://www.amazon.com/Deep-Conviction-Steven-T-Collis-audiobook/dp/B07R549MZB/ref=sr_1_1?crid=1BHWV1R2AGW34&keywords=Steven+Collis%2C+Deep+Conviction&qid=1698377440&sprefix=steven+collis%2C+deep+conviction%2Caps%2C145&sr=8-1",
    type: "Books",
  },
  {
    author: "Kevin Seamus Hasson",
    title: "Believers, Thinkers, and Founders",
    year: 2016,
    link: "https://www.amazon.com/Believers-Thinkers-Founders-Nation-Under/dp/B01D59B3CG/ref=sr_1_1?keywords=Kevin+Seamus+Hasson%2C+Believers%2C+Thinkers%2C+and+Founders&qid=1698377400&sr=8-1",
    type: "Books",
  },
  {
    author: "Kevin Seamus Hasson",
    title: "A Modest St. Paddy's Day Proposal",
    year: 2009,
    category: "Establishment",
    link: "https://www.wsj.com/articles/SB123725627239551027",
    type: "Articles",
  },
  {
    author: "Eric Rassbach",
    title: "Why I Defend Goat Sacrifice",
    year: 2009,
    category: "Free Exercise",
    link: "https://www.wsj.com/articles/SB10001424052970204908604574337071663453560",
    type: "Articles",
  },
  {
    author: "Hannah Smith",
    title: "Happy Religious Freedom Day",
    year: 2016,
    category: "RFRA",
    link: "https://www.foxnews.com/opinion/happy-religious-freedom-day-lets-celebrate-americas-first-freedom",
    type: "Articles",
  },
  {
    author: "Mark Rienzi",
    title:
      "Fool Me Twice: Zubik v. Burwell and the Perils of Judicial Faith in Government Claims",
    year: 2016,
    category: "RFRA",
    link: "https://scholarship.law.edu/scholar/958/",
    type: "Articles",
  },
  {
    author: "Luke Goodrich",
    title:
      "Hobby Lobby owners deserve their religious rights",
    year: 2014,
    category: "Corporations",
    link: "https://archive.sltrib.com/article.php?id=57667178&itype=CMSID",
    type: "Articles",
  },
  {
    author: "Hannah Smith",
    title: "A Hobson's Choice: Religious Freedom in the Business World ",
    year: 2014,
    category: "Corporations",
    link: "https://www.deseret.com/2014/3/9/20536958/a-hobson-s-choice-religious-freedom-in-the-business-world",
    type: "Articles",
  },
  {
    author: "Luke Goodrich",
    title: "Why Religious Freedom for Prisoners Matters",
    year: 2014,
    category: "Prisoners",
    link: "https://thehill.com/blogs/congress-blog/religious-rights/219747-why-religious-freedom-for-prisoners-matters",
    type: "Articles",
  },
  {
    author: "Hannah Smith",
    title: "Prisoners Beards and Religious Freedom",
    year: 2015,
    category: "Prisoners",
    link: "https://www.deseret.com/2015/2/2/20557685/prisoner-beards-and-religious-freedoms-what-a-recent-supreme-court-decision-means-for-you",
    type: "Articles",
  },
  {
    author: "Hannah Smith and Luke Goodrich",
    title: "A Soft landing at the Supreme Court",
    year: 2016,
    category: "Blaine Amendments",
    link: "https://www.scotusblog.com/2016/08/symposium-a-soft-landing-at-the-supreme-court/",
    type: "Articles",
  },
  {
    author: "Stephanie Barclay",
    title:
      "The Religion Clauses After Kennedy v. Bremerton School District",
    year: 2023,
    link: "https://ilr.law.uiowa.edu/sites/ilr.law.uiowa.edu/files/2023-07/A2_Barclay.pdf",
    type: "Articles",
  },
  {
    author: "Anna Bryner,Brady Earley & Daniel Frost",
    title: "What religious freedoms do you actually have",
    year: 2021,
    link: "https://www.deseret.com/opinion/2021/7/19/22579124/what-religious-freedoms-fo-you-actually-have-supreme-court-smith-test-jonas-yoder?fbclid=IwAR24CnQxClggP9N9y4H8PbaE5FzOy5HeZ8et6IsfnQghq10WABKy82QRWu8",
    type: "Articles",
  },
  {
    title: "Sherbert v. Verner",
    year: 1963,
    link: "https://www.oyez.org/cases/1962/526",
    type: "Cases",
  },
  {
    title: "Employment Division v. Smith",
    year: 1990,
    link: "https://www.oyez.org/cases/1989/88-1213",
    type: "Cases",
  },
  {
    title: "Christian Legal Society Chapter v. Martinez",
    year: 2010,
    link: "https://www.oyez.org/cases/2009/08-1371",
    type: "Cases",
  },
  {
    title: "Hosanna-Tabor Evangelical Lutheran Church and School v. EEOC",
    year: 2012,
    link: "https://www.oyez.org/cases/2011/10-553",
    type: "Cases",
  },
  {
    title: "Burwell v. Hobby Lobby Stores",
    year: 2014,
    link: "https://www.oyez.org/cases/2013/13-354",
    type: "Cases",
  },
  {
    title: "Obergefell v. Hodges",
    year: 2015,
    link: "https://www.oyez.org/cases/2014/14-556",
    type: "Cases",
  },
  {
    title: "Holt v. Hobbs",
    year: 2015,
    link: "https://www.oyez.org/cases/2014/13-6827",
    type: "Cases",
  },
  {
    title: "Zubik v. Burwell",
    year: 2016,
    link: "https://www.oyez.org/cases/2015/14-1418",
    type: "Cases",
  },
  {
    title: "Trinity Lutheran Church of Columbia, Inc. v. Comer",
    year: 2017,
    link: "https://www.oyez.org/search/Trinity%20Lutheran%20Church%20of%20Columbia%20v.%20Comer",
    type: "Cases",
  },
  {
    title: "Masterpiece Cakeshop, Ltd. v. Colorado Civil Rights Commission",
    year: 2018,
    link: "https://www.oyez.org/cases/2017/16-111",
    type: "Cases",
  },
  {
    title:
      "Little Sisters of the Poor Saints Peter and Paul Home v. Pennsylvania",
    year: 2020,
    link: "https://www.oyez.org/cases/2019/19-431",
    type: "Cases",
  },
  {
    title: "Our Lady of Guadalupe School v. Morrissey-Berru",
    year: 2020,
    link: "https://www.oyez.org/cases/2019/19-267",
    type: "Cases",
  },
  {
    title: "Fulton v. City of Philadelphia",
    year: 2021,
    link: "https://www.oyez.org/cases/2020/19-123",
    type: "Cases",
  },
  {
    title: "Mahmoud v. McKnight",
    year: "pending 4th Cir. 2023",
    link: "https://www.becketlaw.org/case/mahmoud-v-mcknight/ ",
    type: "Cases"
  },
  {
    title:
      "Huntsman v. Corp. of the President of The Church of Jesus Christ of Latter-day Saints",
    year: "pending 9th Cir. 2023",
    link: "https://www.becketlaw.org/case/huntsman-v-corporation-of-the-president-of-the-church-of-jesus-christ-of-latter-day-saints/",
    type: "Cases"
  },
  {
    title: "Kennedy v. Bremerton School District",
    year: 2022,
    link: "https://www.oyez.org/cases/2021/21-418",
    type: "Cases"
  },
  {
    title: "Carson v. Makin",
    year: 2022,
    link: "https://www.oyez.org/cases/2021/20-1088",
    type: "Cases"
  },
  {
    title: "Espinoza v. Montana Department of Revenue",
    year: 2020,
    link: "https://www.oyez.org/cases/2019/18-1195]",
    type: "Cases"
  },
];

const GetInvolved = (props) => {
  let points = [];
  details.forEach((d) => {
    d.items.forEach((i) => {
      i.points.forEach((p) => {
        points.push(p);
      });
    });
  });
  //console.log("points: ", points);
  return (
    <Container>
      <Row>
        <Col className="text-center mt-4 pb-5 mb-5">
          <h1>Get Involved</h1>
          <Container>
            <Row>
              {gimages.map((op, i) => {
                return (
                  <Col
                    key={`gi_${i}`}
                    xs={6}
                    md={gimages.length === i + 1 ? { span: 4, offset: 4 } : 4}
                    className="text-center mt-3 p-3"
                  >
                    <Image
                      src={op.img}
                      alt={op.alt}
                      style={{
                        //width: "auto",
                        maxHeight: "125px",
                        maxWidth: "100%",
                      }}
                    />
                    <p className="pt-2">{op.desc}</p>
                    <a
                      href={op.link}
                      className="btn btn-secondary btn-lg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </a>
                  </Col>
                );
              })}
            </Row>
          </Container>
          <hr />
          {details.map((d, i) => {
            return (
              <div className="mt-5" key={`details_${i}`}>
                <h4>{d.title.toUpperCase()}</h4>
                {d.items.map((l, it) => {
                  return (
                    <div className="text-left" key={`item_${i}_${it}`}>
                      <h5>{l.name}</h5>
                      <ul>
                        {l.points.map((p, n) => {
                          return (
                            <li key={`point_${i}_${it}_${n}`}>
                              <a
                                href={p.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {p.text}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            );
          })}

          <h3 className="mt-5">Compare Opportunities</h3>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Opportunities</th>
                <th>Undergraduates</th>
                <th>Law Students</th>
                <th>All Graduate Program Students</th>
                <th>Law Graduates</th>
              </tr>
            </thead>
            <tbody>
              {points.map((p, i) => {
                return (
                  <tr key={`tabl_p_${i}`}>
                    <td className="text-left">
                      <a
                        href={p.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-dark"
                      >
                        {p.text}
                        <BoxArrowUpRight className="ml-1" />
                      </a>
                    </td>
                    <td>
                      {p.categories.includes("undergraduates") ? (
                        <Check2 size={25} />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {p.categories.includes("law students") ? (
                        <Check2 size={25} />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {p.categories.includes("graduates") ? (
                        <Check2 size={25} />
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {p.categories.includes("law graduates") ? (
                        <Check2 size={25} />
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

const AboutUs = (props) => {
  const createBio = (b) => {
    return (
      <div className="mb-4" key={`bio_${b.order}`}>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div
            className="border p-1 rounded"
            style={{ width: "210px", height: "210px" }}
          >
            <Image
              rounded
              src={b.img}
              style={{
                objectFit: "cover",
                width: "200px",
                height: "200px",
              }}
            />
          </div>

          <div className="p-2 text-left" style={{ maxWidth: "400px" }}>
            <h5 className="text-info">{b.name}</h5>
            <p style={{ fontSize: "1.05rem" }}>{b.title}</p>
          </div>
        </div>
        <div className="mt-3 d-flex flex-row justify-content-center align-items-center">
          <div className="text-left" style={{ maxWidth: "610px" }}>
            {b.bio}
          </div>
        </div>
      </div>
    );
  };
  return (
    <Container>
      <Row>
        <Col xs={12} className="text-center mt-3">
          <h1>About Us</h1>
          <h4>Professors and Lecturers</h4>
          {bios
            .sort((a, b) => a.order - b.order)
            .filter((b) => b.type === "lecturer")
            .map((b, i) => {
              return createBio(b);
            })}

          <h4>Operations &amp; Management</h4>
          {bios
            .sort((a, b) => a.order - b.order)
            .filter((b) => b.type === "staff")
            .map((b, i) => {
              return createBio(b);
            })}
        </Col>
      </Row>
    </Container>
  );
};

const HowItWorks = (props) => {
  return (
    <Container>
      <Row>
        <Col
          xs={12}
          className="text-center mt-3 mb-5"
          style={{ paddingBottom: "100px" }}
        >
          <h1>How It Works</h1>
          <h4 className="mt-5">How to Create an Account</h4>
          <div
            style={{
              position: "relative",
              paddingBottom: "62.5%",
              height: "0",
            }}
          >
            <iframe
              src="https://www.loom.com/embed/d8b49a3c63984baebe05108d9fe71f30"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          <h5 className="mt-5">Instructions</h5>
          <p className="text-left">
            Welcome to FreerFirst.org. We're so excited your here. Creating a
            free account will allow you watch lesson vidoes and take lesson
            quizzes. The administrator or organization of your organization will
            be able to see that you've created an account and the lessons that
            you've taken. To get started following the steps below:
          </p>
          <ol className="text-left">
            <li>
              Click <i>Create Free Account</i>.
            </li>
            <li>
              From the signup page, enter your email and choose a password.
              Reenter the password. Click <i>Create Account</i>.
            </li>
            <li>
              Enter a cell phone number and verify that you are real person by
              entering the verification number that is texted to your cell
              phone. Click <i>Verify Account</i>.
            </li>
            <li>
              The site will redirect you to the lessons. Select a lesson and
              click the play button twice to start the lesson video.
            </li>
            <li>
              When you are ready and have watched the lesson video, click{" "}
              <i>Start Quiz</i> to take the quiz associated with the lesson.
              Read each quiz question and select an answer. Your progress for
              each quiz is saved as you go, so if you leave a quiz unfinished or
              partially completed, just remember to sign into your account when
              you return to resume the quiz from where you left off.
            </li>
            <li>
              Once you have completed all of the quizzes, you will be prompted
              to download a completion certificate. You can download and print
              the certificate to provide to your professor or organization to
              show that you have completed all of the course lessons.
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  );
};

const ReadingList = (props) => {
  let types = {};
  readingList.forEach((l) => {
    if (!types[l.type]) {
      types[l.type] = [];
    }
    types[l.type].push(l);
  });

  return (
    <Container>
      <Row>
        <Col className="text-center mb-5 pb-5">
          <h1 className="mt-4">READING LIST</h1>
          <div className="text-left">
            {Object.keys(types).map((t) => {
              return (
                <React.Fragment key={t}>
                  <h4 className="mt-5">{t.toUpperCase()}:</h4>
                  {types[t].sort((a,b) => {
                    if(a.year < b.year) { return 1; }
                    if(a.year > b.year) { return -1; }
                    if(typeof a.year === "string" && typeof b.year !== "string") {
                      return -1;
                    }
                    return 0;
                  }).map((l, i) => {
                    return (
                      <p key={`${t}_${i}`}>
                        {l.author ? <span>{l.author}, </span> : ""}
                        {l.title ? (
                          <span className="font-italic">{l.title} </span>
                        ) : (
                          ""
                        )}
                        {l.category ? <span>({l.category}) </span> : ""}
                        {l.year ? <span>({l.year}) </span> : ""}
                        {l.link ? (
                          <a
                            className="ml-2"
                            href={l.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <BoxArrowUpRight />
                          </a>
                        ) : (
                          ""
                        )}
                      </p>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const TopNav = (props) => {
  let { user, loaded, isApp, signOut, next } = props;
  return (
    <React.Fragment>
      <FofBrand />
      <Navbar
        sticky=""
        expand="lg"
        className="mb-0 pb-0 d-block"
        style={{ width: "100%" }}
      >
        <div style={{ width: "33%" }} className="d-inline-block">
          &nbsp;
        </div>
        <Navbar.Brand
          href="/"
          style={{
            fontFamily: "'Times', serif",
            fontSize: "1.9em",
            fontVariant: "small-caps",
            width: "33%",
          }}
          className="pb-0 text-center d-inline-block"
        >
          {/*<span className="bl">T</span>HE <span className="bl">F</span>REEDOM OF <span className="bl">F</span>AITH.*/}
          <img src={logo} alt="freerfirst.org" width="100%" height="auto" />
        </Navbar.Brand>
        <UserNav className="d-none d-md-inline-block pull-right" {...props} />
      </Navbar>
      <Navbar
        sticky=""
        expand="lg"
        style={{
          borderBottom: "solid 1px",
          backgroundColor: "#fff",
          fontSize: ".8em",
        }}
        className="mt-0 pt-0"
      >
        <Container className="justify-content-center">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="justify-content-center">
            <Nav className="justify-content-center pt-0">
              {[
                {
                  href: process.env.REACT_APP_redirect_path,
                  title: "Lessons",
                },
                {
                  href: "/get-involved",
                  title: "Get Involved",
                },
                {
                  href: "/reading-list",
                  title: "Reading List",
                },
                {
                  href: "/about",
                  title: "About Us",
                },
                {
                  href: "/how-it-works",
                  title: "How It Works",
                },
              ].map((link) => {
                return (
                  <Link
                    key={`link_${link.href}`}
                    to={link.href}
                    className="navbar-text pt-1 px-3"
                  >
                    {link.title}
                  </Link>
                );
              })}
              <UserNavLinks
                {...props}
                className="d-md-none"
                linkClassNames="navbar-text pt-1 px-3"
              />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

const UserNavLinks = (props) => {
  const {
    className,
    style,
    user,
    loaded,
    signOut,
    next,
    superAdmin,
    userDoc,
    linkClassNames,
  } = props;
  return (
    <div className={className ? className : ""} style={style ? style : null}>
      {user === null && loaded && (
        <React.Fragment>
          <Nav.Item>
            <Link
              to={`/signup${next ? "?next=" + next : ""}`}
              className={`btn btn-link btn-sm ${
                linkClassNames ? linkClassNames : ""
              }`}
            >
              Create free account
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to={`/login${next ? "?next=" + next : ""}`}
              className={`btn btn-link btn-sm ${
                linkClassNames ? linkClassNames : ""
              }`}
            >
              Sign In
            </Link>
          </Nav.Item>
        </React.Fragment>
      )}
      {user !== null && user !== "" && loaded && (
        <React.Fragment>
          {props.children && props.children}
          <NavDropdown
            id="usernav_dropdown"
            title={<small className="text-muted">{user.email}</small>}
            style={{ zIndex: "1031" }}
            className="d-inline-block"
          >
            {superAdmin && (
              <Link className="dropdown-item" to="/courses">
                Courses
              </Link>
            )}
            <Link
              className="dropdown-item"
              to={process.env.REACT_APP_redirect_path}
            >
              Lessons
            </Link>
            <Link className="dropdown-item" to="/quizzes">
              Quizzes
            </Link>
            <NavDropdown.Divider />
            <Link className="dropdown-item" to="/settings">
              Settings
            </Link>

            {(userDoc.orgAdmin || superAdmin) && (
              <>
                <NavDropdown.Divider />
                <Link className="dropdown-item" to="/org">
                  View Organization
                </Link>
              </>
            )}
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={(e) => signOut(e, user)}>
              <i className="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Sign
              out
            </NavDropdown.Item>
          </NavDropdown>
        </React.Fragment>
      )}
    </div>
  );
};

const UserNav = (props) => {
  const { className, style } = props;
  return (
    <Nav className={className ? className : ""} style={style ? style : null}>
      <UserNavLinks {...props} />
    </Nav>
  );
};

const SideBar = (props) => {
  let { userDoc, isApp, signOut, superAdmin, user } = props;
  const [sideBarIsOpen, setSideBarIsOpen] = useState(true);

  useEffect(() => {
    if (props.sideBarIsOpen) {
      setSideBarIsOpen(sideBarIsOpen);
    }
  });

  let sideBarWidth = sideBarIsOpen ? "16.66%" : "50px";
  return (
    <div
      className="d-none d-md-flex flex-column justify-content-between bg-light"
      style={{
        width: sideBarWidth,
        maxHeight: "100vh",
        minWidth: sideBarIsOpen ? "265px" : "",
      }}
    >
      <div>
        <div style={{ display: "block" }}>
          <Button
            onClick={(e) => setSideBarIsOpen(!sideBarIsOpen)}
            variant="link"
            style={{ display: "inline-block" }}
            className="pull-right"
          >
            {!sideBarIsOpen && (
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            )}
            {sideBarIsOpen && (
              <i
                className="fa fa-chevron-left"
                aria-hidden="true"
                style={{ color: "#00000080" }}
              ></i>
            )}
          </Button>
        </div>
        {sideBarIsOpen && (
          <Nav
            defaultActiveKey="/search"
            className="flex-column mt-4 sideBar ml-3"
          >
            <NavLink id="hw" className="lss_nav_text" to="/">
              {isApp && (
                <span className="text-dark ml-2" style={{ fontSize: "1.6rem" }}>
                  Digests
                </span>
              )}
            </NavLink>
            <NavLink
              to="/digests"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <Journal size={20} />
              &nbsp;Digests
            </NavLink>

            <NavLink
              to="/content"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <CardText size={20} />
              &nbsp;Content
            </NavLink>

            <NavLink
              to="/import"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <ArrowBarDown size={20} />
              &nbsp;Import
            </NavLink>
          </Nav>
        )}
        {!sideBarIsOpen && (
          <Nav
            defaultActiveKey="/search"
            className="flex-column justify-content-between align-items-center mt-4 sideBar sideBarClosed"
            style={{ minHeight: "400px" }}
          >
            <NavLink className="lss_nav_text text-center mt-3" to="/">
              <img src={logo} alt="ListShack" height="40px" width="auto" />
            </NavLink>
            <div
              id="hw"
              style={{ width: "50px", height: "42px", textAlign: "center" }}
            ></div>
            <NavLink
              to="/digests"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <Journal size={20} />
            </NavLink>

            <NavLink
              to="/content"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <CardText size={20} />
            </NavLink>

            <NavLink
              to="/import"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <ArrowBarDown size={20} />
            </NavLink>
          </Nav>
        )}
      </div>
    </div>
  );
};

const Footer = (props) => {
  let { appInterface } = props;
  return (
    <footer id="footer" className="bg-dark" style={{ padding: "50px 0px" }}>
      <Container>
        <Row>
          <Col>
            <a
              className="btn btn-link text-white"
              href={`mailto: ${process.env.REACT_APP_contact_email}`}
              style={{ paddingLeft: "0px" }}
            >
              <i className="fa fa-envelope"></i>{" "}
              {process.env.REACT_APP_contact_email}
            </a>
            <br />
            <a
              className="btn btn-link text-white"
              style={{ paddingLeft: "0px" }}
              href="tel: 833-222-7919"
            >
              <i className="fa fa-phone"></i> 833-222-7919
            </a>
            <p className="text-white">
              <Link className="text-white" to="/privacy">
                Privacy policy
              </Link>{" "}
              |{" "}
              <Link className="text-white" to="/terms">
                Terms of service
              </Link>{" "}
              |{" "}
              <Link className="text-white" to="/ccpa">
                CCPA
              </Link>
            </p>
            <a
              className="text-white"
              href={
                appInterface === "list_shack"
                  ? "/sitemapLs.txt"
                  : appInterface === "list_shack_pro"
                  ? "/sitemap.txt"
                  : appInterface === "listshack_support"
                  ? "/sitemapLss.txt"
                  : "/sitemapLsio.txt"
              }
            >
              Site map
            </a>
          </Col>
          <Col>
            <p className="text-white">All major credit cards accepted</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const FooterSmall = (props) => {
  return (
    <footer className="text-center" style={{ padding: "20px 0px" }}>
      <Container>
        <Row>
          <Col>
            <p
              style={{
                marginBottom: "0px",
                fontSize: ".8em",
                color: "#00000080",
              }}
            >
              <Link to="/privacy" style={{ color: "#00000080" }}>
                Privacy policy
              </Link>
              &nbsp;&#183;&nbsp;
              <Link to="/terms" style={{ color: "#00000080" }}>
                Terms of service
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const NotFound = (props) => {
  return (
    <Container>
      <Row
        style={{ minHeight: "70vh", textAlign: "center", marginTop: "40px" }}
      >
        <Col>
          <h3>Uh oh, there's nothing here :( </h3>
          <p className="lead">
            Click the button below to return to civilization.
          </p>
          <Link to="/" className="btn btn-large btn-info">
            Back to civilization
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

const LoginOrSubscribe = (props) => {
  return (
    <Container>
      <Row
        style={{ minHeight: "50vh", textAlign: "center", marginTop: "40px" }}
      >
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <p className="lead">
            Login or Create a Free account to view this Course
          </p>
          <Link
            to={`/signup?next=${window.location.pathname}`}
            className="btn btn-primary btn-block"
            style={{ maxWidth: "300px" }}
          >
            Create free account
          </Link>
          <Link
            to={`/login?next=${window.location.pathname}`}
            className="btn btn-link btn-block"
            style={{ maxWidth: "300px" }}
          >
            Sign In
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

const RecommendLecturer = (props) => {
  return (
    <Container>
      <Row>
        <Col className="mt-4">
          <h1 className="text-center">Recommend a Lecturer</h1>
          <p className="lead text-center">
            We would love your recommendations for lecturers!
          </p>
          <p>
            Freer First is dedicated to its mission of helping you understand
            more about religious freedom. If you or someone you know may be
            interested in contributing to our free online curriculum, we'd love
            your recommendation. Please send an email to{" "}
            <a href="mailto:info@freerfirst.org">info@freerfirst.org</a>.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

const ShareLessons = (props) => {
  return (
    <Container>
      <Row>
        <Col className="mt-4">
          <h1 className="text-center">Share our Curriculum</h1>
          <p className="lead text-center">
            Offer our curriculum to your organization
          </p>
          <p>
            Freer First is 100% designed to be shared with any organization.
            We've built our curriculum such that anyone can easily create or
            sign into an account, watch the lectures, and take the quizzes. By
            adding your organization to our site, students who select your
            organization will automatically share their quiz results with your
            teachers, professors, or administrators. You can easily include our
            curriculum either as a stand alone course or supplementary to any
            program.
          </p>
          <h4>Who can take the Freer First lessons?</h4>
          <p>
            Freer First is a dynamic online curriculum. Anyone can signup for
            and take our introductory course. We also offer tailored curriculum.
            Courses can be customized to fit your organization's needs.
          </p>
          <h4>How do I add my organization to your site?</h4>
          <p>
            We'd love to add your organizaton to Freer First. Adding your
            organization allows lecturers, professors, and administratiors the
            ability to see who has created accounts from their organization on
            the site and see their quiz results. Send us an email at{" "}
            <a href="mailto:info@freerfirst.org">info@freerfirst.org</a> and
            we'll add your organization to our site.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

// Settings panes
const settingsPanes = [
  {
    id: "profile",
    name: "Profile",
    admin: false,
    activeKey: "sixth",
    content: (props) => {
      return (
        <Tab.Pane eventKey="first">
          <UserProfile
            {...props}
            heading={
              <React.Fragment>
                <h3>Account profile </h3>
                <Form.Text className="mb-3">
                  Manage your account profile
                </Form.Text>
              </React.Fragment>
            }
            size="md"
            nameText="This will be displayed to your organization."
            phoneText="This will be used to contact you via text message about your
            account."
            emailText="This will be used to contact you via email about your account. Use the Update Email settings page if you'd like to update your email."
            addInfo={["org"]}
            orgs={["BYU JRCLS Fellowship", "BYU Hawaii"]}
            requireUserOrg
          />
        </Tab.Pane>
      );
    },
  },
  {
    id: "update_email",
    name: "Update email",
    activeKey: "fourth",
    content: (props) => {
      return (
        <Tab.Pane eventKey="first">
          <UpdateEmail {...props} />
        </Tab.Pane>
      );
    },
  },
  {
    id: "change_password",
    name: "Change password",
    activeKey: "first",
    content: (props) => {
      const { user, handleAlerts } = props;
      return (
        <Tab.Pane eventKey="first">
          <ChangePass user={user} handleAlerts={handleAlerts} />
        </Tab.Pane>
      );
    },
  },
];

// APP CONTENT
const createCourse = async (params, callback) => {
  //listConfig should be shaped like:
  //{
  //  listName: "string",
  //  listStatus: ["s1", "s2"],
  //  showAllTab: true,
  //  showNewTab: true,
  //  showOtherTab: false,
  //  tabs: [ {fieldValue: ["s1", "s2"], tabTitle: "S1 & S2"}]
  //}

  let { courseConfig, aid, uid, currentCourses } = params;
  let defaultListConfig = {
    data: {
      courseName: `Course ${currentCourses.length + 1}`,
      description: `Course created ${new Date().toLocaleString()}`,
      themeColor: "light",
      courseSlug: `course_${currentCourses.length}`,
    },
    createdBy: uid,
    aid,
    favorite: false,
    archived: false,
    _created: Math.round(new Date().getTime() / 1000),
    lastUpdated: Math.round(new Date().getTime() / 1000),
    lessonCount: 0,
  };
  if (!courseConfig) {
    courseConfig = defaultListConfig;
  } else {
    Object.keys(defaultListConfig).map((k) => {
      if (!courseConfig[k]) {
        courseConfig[k] = defaultListConfig[k];
      }
    });
    Object.keys(defaultListConfig.data).map((k) => {
      if (!courseConfig.data[k]) {
        courseConfig.data[k] = defaultListConfig.data[k];
      }
    });
  }

  try {
    let courseRef = db.collection("courses");
    await courseRef.add(courseConfig);
  } catch (err) {
    console.err("Something went wrong in createCourse: ", err);
  }
};

const updateCourse = async (params, callback) => {
  console.log("updateList params: ", params);
  let { courseConfig, aid, uid } = params;
  try {
    let courseRef = db.collection("courses").doc(courseConfig._docId);
    courseConfig.lastUpdated = Math.round(new Date().getTime() / 1000);
    let update = await courseRef.update(courseConfig);
    if (callback) {
      callback(null, update);
    }
    return update;
  } catch (err) {
    console.log("Something went wrong updating the course: ", err);
    if (callback) {
      callback(err);
    }
  }
};

const ManageCourse = (props) => {
  const {
    userDoc,
    user,
    handleState,
    editCourse,
    courseConfig,
    currentCourses,
  } = props;
  const [newListName, setNewListName] = useState("");
  const [newCourseSlug, setNewCourseSlug] = useState("");
  const [themeColor, setThemeColor] = useState("light");
  const [description, setDescription] = useState("");
  const [showCreateList, setShowCreateList] = useState(false);
  //console.log("ManageList listConfig: ", listConfig);
  useEffect(() => {
    if (editCourse) {
      setNewListName(courseConfig.data.courseName);
      setNewCourseSlug(courseConfig.data.courseSlug);
      setThemeColor(courseConfig.data.themeColor);
      setDescription(courseConfig.data.description);
    }
  }, []);
  return (
    <React.Fragment>
      <a
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => setShowCreateList(true)}
      >
        {props.children}
      </a>
      {showCreateList && (
        <Modal
          show={showCreateList}
          onHide={() => setShowCreateList(false)}
          autoFocus={false}
        >
          <Modal.Body className={`bg-${themeColor}`}>
            <Form.Group>
              <Form.Control
                size="lg"
                type="text"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
                placeholder="Add course name"
                autofocus="true"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                size="lg"
                type="text"
                value={newCourseSlug}
                onChange={(e) => setNewCourseSlug(e.target.value)}
                placeholder="Add course slug"
                autofocus="true"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={2}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Add an optional description for your course"
              />
            </Form.Group>
            <Form.Group className="bg-white p-2 rounded">
              <Form.Label>Select color</Form.Label>
              <div className="d-flex flex-row bg-white">
                {[
                  "light",
                  "primary",
                  "secondary",
                  "success",
                  "info",
                  "danger",
                  "warning",
                  "dark",
                ].map((t) => {
                  let cColor = t === "light" ? "dark" : "white";
                  return (
                    <div
                      key={`color-${t}`}
                      onClick={() => setThemeColor(t)}
                      className={`bg-white`}
                    >
                      {themeColor === t && (
                        <CheckSquareFill
                          className={`text-${t} m-1 bg-${cColor}`}
                          size={30}
                        />
                      )}
                      {themeColor !== t && (
                        <DashSquareFill
                          className={`text-${t} m-1 bg-${cColor}`}
                          size={30}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              variant="link"
              size="sm"
              className="text-muted"
              onClick={() => {
                console.log("Clicked nevermind!");
                setShowCreateList(false);
              }}
            >
              Nevermind
            </Button>
            {!editCourse && (
              <Button
                onClick={() => {
                  console.log("Clicked create new list!");
                  createCourse({
                    courseConfig: {
                      data: {
                        courseName: newListName,
                        themeColor,
                        courseSlug: newCourseSlug
                          .replace(/[^a-zA-Z0-9]/g, "_")
                          .toLowerCase(),
                      },
                    },
                    aid: userDoc.id,
                    uid: user.uid,
                    currentCourses,
                  });
                  setNewListName("");
                  setThemeColor("light");
                  setShowCreateList(false);
                }}
              >
                Create list
              </Button>
            )}
            {editCourse && (
              <Button
                onClick={(e) =>
                  updateCourse(
                    {
                      courseConfig: {
                        data: {
                          courseName: newListName,
                          courseSlug: newCourseSlug,
                          themeColor,
                          description,
                        },
                        _docId: courseConfig._docId,
                      },
                      aid: userDoc.id,
                      uid: user.uid,
                    },
                    (error, update) => {
                      if (error) {
                        console.log("Error updating course: ", error);
                      }
                      if (update) {
                        console.log("Course updated: ", update);
                      }
                      setShowCreateList(false);
                    }
                  )
                }
              >
                Update course
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

const FavoriteCourse = (props) => {
  const { courseConfig, size } = props;
  let favorite, themeColor;
  if (courseConfig) {
    favorite = courseConfig.favorite;
    themeColor = courseConfig.data.themeColor;
  }
  const toggleFavorite = (courseConfig) => {
    let newDoc = { ...courseConfig };
    newDoc.favorite = !newDoc.favorite;
    newDoc.lastUpdated = Math.round(new Date().getTime() / 1000);
    newDoc._docRef.update(newDoc);
  };

  return (
    <Button
      onClick={() => toggleFavorite(courseConfig)}
      variant="link"
      className={props.className ? props.className : ""}
      style={props.style ? props.style : null}
    >
      {favorite && <BookmarkStarFill size={size} />}
      {!favorite && (
        <BookmarkPlus
          size={size}
          className={themeColor === "dark" ? "text-white" : `text-dark`}
        />
      )}
    </Button>
  );
};

const Courses = (props) => {
  const { userDoc, user, handleState, createList } = props;

  useEffect(() => {
    handleState({ isApp: true, noDistract: true });
  }, []);
  return (
    <React.Fragment>
      <Collection
        colRef={db}
        collection="courses"
        permissions={["read", "write", "delete"]}
        where={[
          { field: "archived", operand: "!=", term: true },
          { field: "aid", operand: "==", term: userDoc.id },
        ]}
        orderBy={[{ field: "archived" }, { field: "_created" }]}
      >
        <CollectionContext.Consumer>
          {(context) => {
            const { docs, updateDoc, displayDate } = context;
            return (
              <TrelloBoardList
                {...props}
                {...context}
                title={<h3>Courses</h3>}
                cardPath="/course"
                boardCollectionRef={db.collection("courses")}
                config={{}}
              />
            );
          }}
        </CollectionContext.Consumer>
      </Collection>
    </React.Fragment>
  );
};

const LessonRouter = (props) => {
  const { course } = props;
  return (
    <Switch>
      <Route
        exact
        path={["/course/:courseid/lesson/:lessonslug", "/course/:courseid"]}
        key={course ? course._docId : "course"}
      >
        <Course {...props} />
      </Route>
      <Route exact path={["/lesson", "/lessons", "/lesson/", "/lessons/"]}>
        <Redirect to="/intro/lesson/1" />
      </Route>
    </Switch>
  );
};

const Course = (props) => {
  const { userDoc, handleState, course, superAdmin } = props;
  let { courseid } = useParams();
  const fetchCourse = async (params, callback) => {
    const { courseid } = params;
    let courses = [];
    await db
      .collection("courses")
      .doc(courseid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let c = doc.data();
          c._docId = doc.id;
          c._docRef = doc.ref;
          courses.push(c);
          //console.log("c: ", c);
        }
      });
    if (callback) {
      callback(null, courses);
    }
    return await courses;
  };

  useEffect(() => {
    console.log("fetching course!", courseid);
    let shouldFetch = !course ? true : course._docId !== courseid;
    if (shouldFetch) {
      fetchCourse({ courseid }, (error, courses) => {
        if (error) {
          console.log("Error fetching courses: ", error);
          handleState({
            noDistract: true,
          });
        }
        if (courses) {
          handleState({
            course: courses[0],
            noDistract: true,
          });
        }
      });
    }
    if (course) {
      handleState({ noDistract: true, isApp: true });
    }
  }, [userDoc, courseid]);

  if (!course) {
    return <NotFound />;
  }

  return (
    <Collection
      colRef={db}
      collection="lessons"
      permissions={["read", "write", "delete"]}
      where={[
        { field: "archived", operand: "!=", term: true },
        { field: "aid", operand: "==", term: course.aid },
        { field: "course", operand: "array-contains", term: course._docId },
      ]}
      orderBy={[{ field: "archived" }, { field: "data.order" }]}
    >
      <CollectionContext.Consumer>
        {(context) => {
          const { docs } = context;
          let isAuthor = userDoc
            ? userDoc.id === course.aid
              ? true
              : false
            : false;
          return (
            <Lesson
              {...props}
              {...context}
              course={course}
              lessons={
                isAuthor
                  ? docs
                  : superAdmin
                  ? docs
                  : docs.filter((d) => d.data.published !== false)
              }
              isAuthor={isAuthor}
            />
          );
        }}
      </CollectionContext.Consumer>
    </Collection>
  );
};

const LessonViewHeader = (props) => {
  //console.log("LessonViewHeader props: ", props);
  const { userDoc, config, lessons } = props;
  const [redirect, setRedirect] = useState(null);
  // After redirect, reset the state variable so that the component is rendered.
  useEffect(() => {
    setRedirect(null);
  }, [redirect]);

  if (redirect) {
    return <Redirect push to={`/course/${config._docId}/lesson/${redirect}`} />;
  }
  return (
    <TrelloBoardHeader
      {...props}
      backPath="/"
      config={config}
      rightButtons={[
        <Button
          key="lvh_add_lesson"
          variant={
            config.themeColor
              ? config.themeColor === "light"
                ? "outline-dark"
                : `outline-${config.themeColor}`
              : "outline-dark"
          }
          onClick={async (e) => {
            e.preventDefault();
            let newLesson = await createLesson({
              aid: userDoc.id,
              courseConfig: config,
              lessons,
            });
            console.log("newLesson: ", newLesson);
            setRedirect(newLesson.data.slug);
          }}
        >
          Add Lesson
        </Button>,
        ...props.rightButtons,
      ]}
      hideFavorite
    />
  );
};

const createLesson = async (params, callback) => {
  //contentArticle should be shaped like:
  //{
  //  _created: Number: seconds since epoch,
  //  _lastChanged: Number: seconds sinc epoch,
  //  aid: String: account,
  //  archived: Boolean,
  //  category: Array,
  //  data: Object: {
  //    content: String,
  //    contentType: Enum: html, md, text
  //    imgUrl: String,
  //    link: String,
  //    linkText: String,
  //    preview: String,
  //    title: String
  //  },
  //  description: String
  //}
  let {
    aid,
    courseConfig,
    lessons,
    transcript,
    order,
    quiz,
    slug,
    description,
    title,
    instructor,
    videoThumb,
    videoType,
    videoUrl,
    published,
  } = params;

  if (!aid) {
    return console.log("createArticle requires the aid param.");
  }

  let lesRef = db.collection("lessons");
  let today = Math.round(new Date().getTime() / 1000);
  if (!published) {
    published = false;
  }

  if (!transcript) {
    transcript = "";
  }
  if (!videoThumb) {
    videoThumb = [];
  }
  if (!videoUrl) {
    videoUrl = "";
  }
  if (!videoType) {
    videoType = "youtube";
  }
  if (!order) {
    order = lessons ? lessons.length + 1 : 1;
  }
  if (!description) {
    description = `Lesson ${lessons ? lessons.length + 1 : "1"} of course ${
      courseConfig.name
    }`;
  }
  if (!title) {
    title = `Lesson ${lessons ? lessons.length + 1 : "1"}`;
  }
  if (!instructor) {
    instructor = "";
  }
  if (!slug) {
    slug = lessons ? lessons.length + 1 : 1;
  }
  if (!quiz) {
    quiz = {
      name: "Add quiz name",
      questions: [],
    };
  }
  let newLesson = {
    _created: today,
    lastUpated: today,
    archived: false,
    aid,
    course: [courseConfig._docId],
    data: {
      published,
      transcript,
      videoThumb,
      videoUrl,
      videoType,
      order,
      description,
      instructor,
      title,
      slug,
      quiz,
    },
    themeColor: "info",
    favorite: false,
  };

  let savedLessonRef = await lesRef.add(newLesson).catch((err) => {
    console.log("Error creating article: ", err);
    if (callback) {
      callback(err);
    }
  });
  newLesson._docId = savedLessonRef.id;
  newLesson._docRef = savedLessonRef;

  if (callback) {
    callback(null, newLesson);
  }
  return newLesson;
};

const EditLesson = (props) => {
  const { lesson, onDoneEditing, handleAlerts } = props;
  // control when we fetch data
  const [listenerAdded, setListenerAdded] = useState(false);
  const [saved, setSaved] = useState(true);
  // editing state variables
  const [published, setPublished] = useState(
    lesson.data.published ? lesson.data.published : false
  );
  const [title, setTitle] = useState(lesson.data.title);
  const [instructor, setInstructor] = useState(
    lesson.data.instructor ? lesson.data.instructor : ""
  );
  const [transcript, setTranscript] = useState(lesson.data.transcript);
  const [description, setDescription] = useState(lesson.data.description);
  const [videoThumb, setVideoThumb] = useState(lesson.data.videoThumb);
  const [videoType, setVideoType] = useState(lesson.data.videoType);
  const [videoUrl, setVideoUrl] = useState(lesson.data.videoUrl);
  const [quiz, setQuiz] = useState(lesson.data.quiz);
  const [files, setFiles] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);

  const storeInputFile = async (file) => {
    // upload the file to storage
    let fileOfInputs = await storeMedia(
      `lessons/${props.userDoc.id}/${file.name}`,
      file,
      // progressCallBack
      (progress) => {
        console.log("progress: ", progress);
      },
      // downloadCallBack
      (downloadUrl) => {
        console.log("dowloadUrl: ", downloadUrl);
        setVideoThumb([downloadUrl, ...videoThumb]);
      },
      // metadataCallBack
      (metadata) => {
        console.log("metadata: ", metadata);
      }
    );
  };

  useEffect(() => {
    let unsubscribe;
    let aref = db.collection("lessons").doc(lesson._docId);
    if (!listenerAdded) {
      //setTitle(activeLesson.data.title);
      //setTranscript(activeLesson.data.transcript);
      //setDescription(activeLesson.data.description);
      //setVideoThumb(activeLesson.data.videoThumb);
      //setVideoType(activeLesson.data.videoType);
      //setVideoUrl(activeLesson.data.videoUrl);
      //setQuizId(activeLesson.data.quizId);
      setListenerAdded(true);
      //unsubscribe = aref.get().then( doc => {
      //  if (doc.exists) {
      //    let d = doc.data();
      //    console.log("d: ", d);
      //    setTitle(d.data.title);
      //    setTranscript(d.data.transcript);
      //    setDescription(d.data.description);
      //    setVideoThumb(d.data.videoThumb);
      //    setVideoType(d.data.videoType);
      //    setVideoUrl(d.data.videoUrl);
      //    setQuizId(d.data.quizId);
      //    setListenerAdded(true);
      //  }
      //});
    }

    if (listenerAdded) {
      let timeOutId = setTimeout(async () => {
        console.log("Done typing.");
        await aref.update({
          _lastChanged: Math.round(new Date().getTime() / 1000),
          "data.transcript": transcript,
          "data.title": title,
          "data.instructor": instructor,
          "data.description": description,
          "data.videoThumb": videoThumb,
          "data.videoType": videoType,
          "data.videoUrl": videoUrl,
          "data.quiz": quiz,
          "data.published": published,
        });
        setSaved(true);
      }, 2000);
      return () => {
        console.log("Clearing timeout");
        clearTimeout(timeOutId);
        setSaved(false);
      };
    }
  }, [
    title,
    instructor,
    transcript,
    description,
    videoThumb,
    videoUrl,
    videoType,
    quiz,
    published,
  ]);

  return (
    <Col className="p-2 m-3">
      <div className="d-flex flex-row justify-content-between mb-3">
        {props.title ? props.title : <div>&nbsp;</div>}
        <ButtonGroup className="pull-right mr-5 d-flex flex-row justify-content-center align-items-center">
          <Form.Check
            className="mr-2"
            id="published_switch"
            type="switch"
            onChange={() => {
              if (
                typeof quiz.questions === "undefined" ||
                quiz.questions.length === 0
              ) {
                return handleAlerts(
                  "",
                  "You must add a quiz before you can publish this lesson.",
                  "danger"
                );
              }
              setPublished(!published);
            }}
            checked={published}
            label="Published"
          />
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              if (onDoneEditing) {
                onDoneEditing();
              }
            }}
            disabled={!saved}
          >
            {saved ? "Done Editing" : "Saving"}
          </Button>
        </ButtonGroup>
      </div>
      <div style={{ width: "600px" }}>
        <Image
          src={videoThumb[0]}
          rounded
          style={{ width: "100%", height: "auto" }}
        />

        <Form.Group>
          <Form.File
            ref={fileInputRef}
            className=""
            name="file"
            label="Upload a new featured image"
            onChange={(e) => {
              e.preventDefault();
              const { files } = e.target;
              console.log("files: ", files);
              storeInputFile(files[0]);
            }}
            id="selectArtFile"
            accept="image/jpeg, image/png, image/gif, image/svg+xml, image/tiff"
          />
          {videoThumb.length > 1 && (
            <div className="mt-3">
              <h6>Click an image to use it as the featured image.</h6>
              <div className="d-flex flex-row flex-wrap">
                {videoThumb.slice(1).map((url, i) => {
                  return (
                    <div
                      style={{
                        height: "100px",
                        width: "160px",
                        paddingRight: "10px",
                        position: "relative",
                      }}
                      key={`simg_${i}`}
                    >
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          console.log("Set image as featured");
                          setVideoThumb([
                            url,
                            ...videoThumb.filter((u) => u !== url),
                          ]);
                        }}
                      >
                        <Image
                          thumbnail
                          src={url}
                          style={{
                            height: "100px",
                            width: "150px",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                      <a
                        style={{
                          position: "absolute",
                          right: "15px",
                          top: "5px",
                          padding: "2.5px",
                          backgroundColor: "#fff",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          textAlign: "center",
                        }}
                        href="#"
                        className="d-flex justify-content-center align-items-center"
                        onClick={() => {
                          console.log("Removing image.");
                          setVideoThumb([
                            ...videoThumb.filter((u) => u !== url),
                          ]);
                        }}
                      >
                        <Trash size={14} className="text-danger" />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Form.Group>

        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(e) => {
              e.preventDefault();
              setTitle(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Instructor</Form.Label>
          <Form.Control
            type="text"
            value={instructor}
            onChange={(e) => {
              e.preventDefault();
              setInstructor(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            rows="3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Transcript</Form.Label>
          <MDEditor
            value={transcript}
            onChange={setTranscript}
            preview="edit"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Video Url and Type</Form.Label>
          <Form.Control
            type="text"
            placeholder="Add video url"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
          />
          <Form.Control
            as="select"
            value={videoType}
            onChange={(e) => setVideoType(e.target.value)}
          >
            <option>Select the type of video link</option>
            <option value="youtube">YouTube</option>
            <option value="vimeo">Vimeo</option>
            <option value="loom">Loom</option>
          </Form.Control>
        </Form.Group>
        <hr />
        <h5>Lesson Quiz</h5>
        <Form.Group>
          <Form.Label>Quiz Name</Form.Label>
          <Form.Control
            type="text"
            value={quiz.name}
            onChange={(e) => {
              let nq = { ...quiz };
              nq.name = e.target.value;
              setQuiz(nq);
            }}
            placeholder="Name your quiz"
          />
        </Form.Group>
        {quiz.questions &&
          quiz.questions.map((q, i) => {
            return (
              <Form.Group key={`addQuestion_${i}`}>
                <Form.Label>Question {i + 1}</Form.Label>
                <MDEditor
                  value={quiz.questions[i].question}
                  onChange={(newQuestion) => {
                    let nq = { ...quiz };
                    nq.questions[i].question = newQuestion;
                    setQuiz(nq);
                  }}
                  preview="edit"
                />
                <Form.Label className="mt-3">Answer choice</Form.Label>
                <br />
                {q.options.map((o, it) => {
                  return (
                    <InputGroup key={`q_${i}_o_${it}`} className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text>{letterMap[it]}</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        value={o}
                        onChange={(e) => {
                          let nq = { ...quiz };
                          nq.questions[i].options[it] = e.target.value;
                          setQuiz(nq);
                        }}
                        placeholder="Add option"
                      />
                      <InputGroup.Append>
                        <InputGroup.Checkbox
                          id={`q_${i}_o_sw_${it}`}
                          checked={quiz.questions[i].answer === o}
                          onChange={() => {
                            let nq = { ...quiz };
                            nq.questions[i].answer = o;
                            setQuiz(nq);
                          }}
                          label="Correct"
                        />
                        <Button
                          variant="outline-danger"
                          className="text-danger"
                          onClick={() => {
                            let nq = { ...quiz };
                            let options = nq.questions[i].options
                              .map((opt) => {
                                if (opt !== o) {
                                  return opt;
                                }
                              })
                              .filter((opt) => typeof opt !== "undefined");
                            nq.questions[i].options = options;
                            console.log("nq: ", nq);
                            setQuiz(nq);
                          }}
                        >
                          <Trash />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  );
                })}

                <Button
                  variant="outline-dark"
                  size="sm"
                  onClick={() => {
                    let nq = { ...quiz };
                    nq.questions[i].options.push("");
                    setQuiz(nq);
                  }}
                >
                  Add answer choice
                </Button>
              </Form.Group>
            );
          })}
        <Button
          variant="dark"
          className="mt-3"
          onClick={() => {
            let nq = { ...quiz };
            nq.questions = [
              ...nq.questions,
              { answer: "", options: [], question: "Type your question here." },
            ];
            //console.log("nq: ", nq);
            setQuiz(nq);
          }}
        >
          Add Question
        </Button>
      </div>
    </Col>
  );
};

const createCourseCertificate = ({ course, finishedQuizzes, userDoc }) => {
  function getTextWidth(text, font) {
    // re-use canvas object for better performance
    var canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = window.document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width;
  }

  const bimg = 75;
  const pmar = 5;
  const pw = 1056;
  const ph = 816;
  let doc = new jsPDF({
    format: "letter",
    orientation: "landscape",
    unit: "px",
    hotfixes: ["px_scaling"],
  });
  // 11 x 8.5 in === 1056 x 816 px
  // Add the images
  doc.addImage(border_tl, "PNG", pmar, pmar, bimg, bimg);
  doc.addImage(border_tr, "PNG", pw - pmar - bimg, pmar, bimg, bimg);
  doc.addImage(
    border_br,
    "PNG",
    pw - pmar - bimg,
    ph - pmar - bimg,
    bimg,
    bimg
  );
  doc.addImage(border_bl, "PNG", pmar, ph - pmar - bimg, bimg, bimg);
  doc.addImage(quality, "JPG", pw / 2 - bimg / 2, 100, bimg, bimg);

  // Add the Yellow Text
  doc.setFont("times");
  doc.setFontSize(26);
  doc.setTextColor("EACB48");
  let title = "CERTIFICATE OF COMPLETION";
  doc.text(title, pw / 2 - getTextWidth(title, "26pt times") / 2, 160 + bimg);
  const name = userDoc.userProfile.name;
  doc.setFontSize(40);
  doc.setFont("times", "italic", 900);
  doc.text(
    name,
    pw / 2 - getTextWidth(name, "italic 40pt times") / 2,
    160 + bimg + 120
  );

  doc.setFontSize(14);
  doc.setFont("times", "normal", 900);
  let siteName = process.env.REACT_APP_site_name.toUpperCase();
  doc.text(
    siteName,
    pw / 2 - getTextWidth(siteName, "14pt times") / 2,
    ph - 30
  );

  // Add the Black Text
  doc.setFont("Times", "normal", 400);
  doc.setFontSize(18);
  doc.setTextColor("111111");
  let prt = `This Certifies That`;
  doc.text(prt, pw / 2 - getTextWidth(prt, "18pt arial") / 2, 160 + bimg + 40);

  doc.setFontSize(16);
  let des = `Has Completed the Course`;
  doc.text(des, pw / 2 - getTextWidth(des, "16pt arial") / 2, 160 + bimg + 180);
  let cn = course.data.courseName;
  doc.text(cn, pw / 2 - getTextWidth(cn, "16pt arial") / 2, 160 + bimg + 204);

  doc.setFontSize(14);
  doc.setFont("times", "bold", 900);
  let lt = "Lessons";
  doc.text(lt, pw / 2 - getTextWidth(lt, "14pt times") / 2, 160 + bimg + 270);

  doc.setFontSize(10);
  doc.setFont("times", "normal", 400);
  finishedQuizzes
    .sort((a, b) => {
      return a.lastUpdated - b.lastUpdated;
    })
    .map((q, i) => {
      let correct = 0;
      q.questions.map((qu) => {
        if (qu.answer === qu.selectedAnswer) {
          correct += 1;
        }
      });

      let score = `${q.name} - ${new Date(
        q.lastUpdated * 1000
      ).toLocaleString()}`;
      doc.text(
        score,
        pw / 2 - getTextWidth(score, "10pt times") / 2,
        160 + bimg + 290 + (i + 1) * 15
      );
    });
  doc.save(`Course-${course.name}-certificate_${userDoc.uid}`);
};

const VideoPlayer = (props) => {
  const { thumbnail, youtubeid, vimeoid } = props;
  const [playVideo, setPlayVideo] = useState(false);

  useEffect(() => {
    setPlayVideo(false);
  }, [youtubeid]);

  return (
    <React.Fragment>
      {!playVideo && (
        <a
          style={{
            position: "relative",
          }}
          onClick={() => setPlayVideo(true)}
        >
          <img
            src={thumbnail}
            style={
              props.style
                ? props.style
                : {
                    width: "100%",
                    height: "360px",
                    objectFit: "contain",
                  }
            }
          />
          <div
            className="text-info"
            style={{
              position: "absolute",
              top: "-50%",
              left: "50%",
              cursor: "pointer",
            }}
          >
            <PlayCircleFill size={60} />
          </div>
        </a>
      )}
      {playVideo && youtubeid && (
        <iframe
          width="100%"
          height="360"
          src={`https://www.youtube.com/embed/${youtubeid}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
          allowFullScreen
          autoplay={1}
        ></iframe>
      )}
      {playVideo && vimeoid && (
        <React.Fragment>
          <div
            style={{
              padding: "75% 0 0 0",
              position: "relative",
            }}
          >
            <iframe
              src={`https://player.vimeo.com/video/${vimeoid}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              title={props.title ? props.title : ""}
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Lesson = (props) => {
  //console.log("Lesson props: ", props);
  let {
    userDoc,
    user,
    lessons,
    course,
    handleState,
    isApp,
    superAdmin,
    isAuthor,
  } = props;
  let { lessonslug, courseid } = useParams();
  let activeLesson = lessons.find(
    (lesson) => lesson.data.slug.toString() === lessonslug
  );
  //console.log("activeLesson: ", activeLesson);
  let alRetake, alHasQuiz, isFinished, finishedQuizzes;
  // UI state variables
  const [showEdit, setShowEdit] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const [userQuizzes, setUserQuizzes] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const calcTheme = course
    ? course.themeColor
      ? course.themeColor === "light"
        ? "outline-dark"
        : `outline-${course.themeColor}`
      : "outline-dark"
    : "outline-dark";
  //console.log("content: ", content);

  if (activeLesson) {
    //console.log("activeLesson.videoType: ", activeLesson.data.videoType, activeLesson.data.videoUrl.split("/")[activeLesson.data.videoUrl.split("/").length -1]);
    alHasQuiz = userQuizzes
      ? userQuizzes.find((q) => activeLesson._docId === q.lessonId)
      : false;
    if (alHasQuiz) {
      alRetake = alHasQuiz.graded === true && userDoc;
    }
  }

  finishedQuizzes = userQuizzes
    ? userQuizzes
        .map((q) => {
          if (lessons.find((l) => l._docId === q.lessonId)) {
            if (q.graded) {
              return q;
            }
          }
        })
        .filter((q) => q !== undefined)
    : [];
  isFinished =
    finishedQuizzes.length === lessons.length && finishedQuizzes.length > 0;

  useEffect(() => {
    let unsubscribe;
    if (userDoc && !userQuizzes) {
      unsubscribe = db
        .collection("quizzes")
        .where("uid", "==", userDoc.uid)
        .onSnapshot((querySnaphot) => {
          let quizzes = [];
          querySnaphot.forEach((doc) => {
            let quiz = doc.data();
            quiz._docId = doc.id;
            quiz._docRef = doc.ref;
            quizzes.push(quiz);
          });
          setUserQuizzes(quizzes);
        });
    }
    if (!isApp) {
      handleState({
        isApp: true,
        noDistract: true,
      });
    }
    return () => {
      if (userDoc) {
        try {
          unsubscribe();
        } catch (err) {
          console.log("Error unsubscribing: ", err);
        }
      }
    };
  }, [userDoc]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const takeQuiz = async ({ lesson, userDoc }) => {
    let quizConfig = await createBoard({
      aid: userDoc.id,
      uid: userDoc.uid,
      ref: db.collection("quizzes"),
      config: {
        ...lesson.data.quiz,
        lessonId: lesson._docId,
      },
      currentBoards: [],
    });
    console.log("quizConfig: ", quizConfig);
    handleState({
      backTo: {
        path: `/course/${lesson.course}/lesson/${lesson.data.slug}`,
        text: `${course.data.courseName} : ${lesson.data.title}`,
      },
    });
    setRedirect(`/quiz/${quizConfig._docId}`);
  };

  return (
    <Container fluid id="lessonsView" className="bg-light">
      <Row style={{ minHeight: "100vh" }} className="flex-column">
        {showEdit && (
          <EditLesson
            {...props}
            title={<h3>Edit Lesson</h3>}
            lesson={activeLesson}
            onDoneEditing={() => {
              setShowPreview(true);
              setShowEdit(false);
            }}
          />
        )}
        {showPreview && (
          <Col>
            {(isAuthor || superAdmin) && (
              <LessonViewHeader
                {...props}
                config={course}
                rightButtons={[
                  <Button
                    key="edit_button"
                    variant={calcTheme}
                    onClick={() => {
                      setShowEdit(!showEdit);
                      setShowPreview(!showPreview);
                    }}
                    disabled={!activeLesson}
                  >
                    {!showEdit ? "Edit Lesson" : "Done Editing"}
                  </Button>,
                  <Button
                    className="mr-2"
                    key="archive_button"
                    variant={calcTheme}
                    onClick={() => {
                      console.log("Clicked to archive lesson");
                      archiveDoc({
                        docRef: activeLesson._docRef,
                      });
                    }}
                  >
                    Archive lesson
                  </Button>,
                ]}
              />
            )}
            <main id="lessons">
              <section id="lessons" style={{ textAlign: "center" }}>
                <Container
                  style={{ marginTop: "30px", paddingBottom: "100px" }}
                >
                  <Row noGutters>
                    {isFinished && (
                      <Col xs={12}>
                        <Alert variant="success">
                          <Alert.Heading>
                            You've finished this Course!
                          </Alert.Heading>
                          <p>
                            Click here to print a course certificate.
                            <Button
                              variant="outline-success"
                              className="ml-3"
                              onClick={() =>
                                createCourseCertificate({
                                  finishedQuizzes,
                                  userDoc,
                                  course,
                                })
                              }
                            >
                              Print Certificate
                            </Button>
                          </p>
                        </Alert>
                      </Col>
                    )}
                    <Col md={5} className="text-left pr-2">
                      <div className="bg-white box-shadow">
                        <h4 className="p-2">Lessons</h4>
                        <Nav variant="pills" className="flex-column">
                          {lessons.map((lesson, i) => {
                            let retake, finished, progress;
                            let hasQuiz = userQuizzes
                              ? userQuizzes.find(
                                  (q) => lesson._docId === q.lessonId
                                )
                              : false;
                            if (hasQuiz) {
                              retake = hasQuiz.graded === true && userDoc;
                              finished = 0;
                              hasQuiz.questions.map((q) => {
                                if (q.selectedAnswer) {
                                  finished += 1;
                                }
                              });
                              progress =
                                (finished / hasQuiz.questions.length) * 100;
                            }
                            return (
                              <Link
                                to={`/course/${courseid}/lesson/${lesson.data.slug}`}
                                key={`nav_${lesson.data.order}`}
                                className={`nav-item text-center d-flex flex-row nav-link ${
                                  lessonslug === lesson.data.slug.toString()
                                    ? "lesson-active"
                                    : ""
                                }`}
                                style={{
                                  minHeight: "100px",
                                  border: "solid 1px lightgray",
                                  fontFamily: "'Times', serif",
                                }}
                              >
                                <div
                                  className="lesson-number"
                                  style={{
                                    color: "lightgray",
                                    fontSize: "1.5rem",
                                    width: "10%",
                                  }}
                                >
                                  {i + 1}.
                                </div>
                                <div
                                  className="text-left p-1"
                                  style={{
                                    width: "45%",
                                    textIndent: "-0.5rem",
                                  }}
                                >
                                  <span
                                    className="lesson-title p-1 text-dark"
                                    style={{
                                      fontSize: "1.2rem",
                                      lineHeight: ".5",
                                      textIndent: "-1.5rem",
                                    }}
                                  >
                                    {lesson.data.title}
                                  </span>{" "}
                                  <br />
                                  <React.Fragment>
                                    {!hasQuiz && userDoc && (
                                      <Button
                                        variant="link"
                                        className="text-info"
                                        onClick={() => {
                                          takeQuiz({ lesson, userDoc });
                                        }}
                                        style={{
                                          fontSize: ".8rem",
                                        }}
                                      >
                                        Start Quiz
                                      </Button>
                                    )}
                                    {retake && (
                                      <div className="d-inline-block">
                                        <small className="text-dark">
                                          You scored <b>{finished}</b> out of{" "}
                                          <b>{hasQuiz.questions.length}</b>
                                        </small>{" "}
                                        <br />
                                        <small className="pull-left">
                                          <Button
                                            variant="link"
                                            className="text-info p-0"
                                            style={{
                                              fontSize: ".8rem",
                                              border: "none",
                                              lineHeight: "inherit",
                                            }}
                                            onClick={async () => {
                                              await retakeQuiz({
                                                quiz: hasQuiz,
                                              });
                                              handleState({
                                                backTo: {
                                                  path: `/course/${lesson.course}/lesson/${lesson.data.slug}`,
                                                  text: `${course.data.courseName} : ${lesson.data.title}`,
                                                },
                                              });
                                              setRedirect(
                                                `/quiz/${hasQuiz._docId}`
                                              );
                                            }}
                                          >
                                            Retake quiz
                                          </Button>
                                          <span className="text-dark"> - </span>
                                          <Link
                                            className="btn btn-link text-info p-0 ml-2"
                                            style={{
                                              fontSize: ".8rem",
                                              border: "none",
                                              lineHeight: "inherit",
                                            }}
                                            to={`/quiz/${hasQuiz._docId}`}
                                            onClick={() => {
                                              handleState({
                                                backTo: {
                                                  path: `/course/${lesson.course}/lesson/${lesson.data.slug}`,
                                                  text: `${course.data.courseName} : ${lesson.data.title}`,
                                                },
                                              });
                                            }}
                                          >
                                            Review quiz
                                          </Link>
                                        </small>
                                      </div>
                                    )}
                                    {hasQuiz && !retake && (
                                      <div className="d-inline-block">
                                        <small className="text-dark">
                                          You completed <b>{finished}</b> out of{" "}
                                          <b>{hasQuiz.questions.length}</b>
                                        </small>{" "}
                                        <br />
                                        <small className="pull-left">
                                          <Button
                                            variant="link"
                                            className="text-info p-0"
                                            style={{
                                              fontSize: ".8rem",
                                              border: "none",
                                              lineHeight: "inherit",
                                            }}
                                            onClick={async () => {
                                              await retakeQuiz({
                                                quiz: hasQuiz,
                                              });
                                              handleState({
                                                backTo: {
                                                  path: `/course/${lesson.course}/lesson/${lesson.data.slug}`,
                                                  text: `${course.data.courseName} : ${lesson.data.title}`,
                                                },
                                              });
                                              setRedirect(
                                                `/quiz/${hasQuiz._docId}`
                                              );
                                            }}
                                          >
                                            Start over
                                          </Button>
                                          <span className="text-dark"> - </span>
                                          <Link
                                            className="btn btn-link text-info p-0 ml-2"
                                            style={{
                                              fontSize: ".8rem",
                                              border: "none",
                                              lineHeight: "inherit",
                                            }}
                                            to={`/quiz/${hasQuiz._docId}`}
                                            onClick={() => {
                                              handleState({
                                                backTo: {
                                                  path: `/course/${lesson.course}/lesson/${lesson.data.slug}`,
                                                  text: `${course.data.courseName} : ${lesson.data.title}`,
                                                },
                                              });
                                            }}
                                          >
                                            Resume quiz
                                          </Link>
                                        </small>
                                      </div>
                                    )}
                                    {!lesson.data.published && (
                                      <div className="text-muted">DRAFT</div>
                                    )}
                                  </React.Fragment>
                                </div>

                                <div
                                  style={{
                                    width: "45%",
                                    height: "68px",
                                  }}
                                >
                                  <img
                                    src={
                                      lesson.data.videoThumb[0]
                                        ? lesson.data.videoThumb[0]
                                        : "https://via.placeholder.com/200x133/fff?text=Add+Image"
                                    }
                                    style={{
                                      objectFit: "contain",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                  {hasQuiz && (
                                    <ProgressBar
                                      variant="info"
                                      now={progress}
                                    />
                                  )}
                                </div>
                              </Link>
                            );
                          })}
                        </Nav>
                      </div>
                    </Col>
                    <Col xs={12} md={7} className="pl-3">
                      <Row noGutters>
                        <Col xs={12}>
                          {!user && <LoginOrSubscribe {...props} />}
                          {user && activeLesson && (
                            <React.Fragment>
                              <div className="p-2 bg-white box-shadow">
                                {activeLesson.data.videoType === "html5" && (
                                  <video width="100%" height="auto" controls>
                                    <source src={activeLesson.data.videoUrl} />
                                  </video>
                                )}
                                {["vimeo", "youtube"].includes(
                                  activeLesson.data.videoType
                                ) && (
                                  <VideoPlayer
                                    thumbnail={
                                      activeLesson.data.videoThumb[0]
                                        ? activeLesson.data.videoThumb[0]
                                        : "https://via.placeholder.com/200x133/fff?text=Add+Image"
                                    }
                                    youtubeid={
                                      activeLesson.data.videoType === "youtube"
                                        ? activeLesson.data.videoUrl.split("/")[
                                            activeLesson.data.videoUrl.split(
                                              "/"
                                            ).length - 1
                                          ]
                                        : false
                                    }
                                    vimeoid={
                                      activeLesson.data.videoType === "vimeo"
                                        ? activeLesson.data.videoUrl.split("/")[
                                            activeLesson.data.videoUrl.split(
                                              "/"
                                            ).length - 1
                                          ]
                                        : false
                                    }
                                  />
                                )}
                              </div>
                              <div className="p-2 bg-white box-shadow border-gold">
                                <h3>{activeLesson.data.title}</h3>
                                <p>{activeLesson.data.description}</p>
                                {alHasQuiz && !alRetake && (
                                  <Link
                                    className="btn btn-info"
                                    to={`/quiz/${alHasQuiz._docId}`}
                                    onClick={() => {
                                      handleState({
                                        backTo: {
                                          path: `/course/${activeLesson.course}/lesson/${activeLesson.data.slug}`,
                                          text: `${course.data.courseName} : ${activeLesson.data.title}`,
                                        },
                                      });
                                    }}
                                  >
                                    Resume Quiz
                                  </Link>
                                )}
                                {alHasQuiz && alRetake && (
                                  <Button
                                    variant="info"
                                    onClick={async () => {
                                      await retakeQuiz({ quiz: alHasQuiz });
                                      handleState({
                                        backTo: {
                                          path: `/course/${activeLesson.course}/lesson/${activeLesson.data.slug}`,
                                          text: `${course.data.courseName} : ${activeLesson.data.title}`,
                                        },
                                      });
                                      setRedirect(`/quiz/${alHasQuiz._docId}`);
                                    }}
                                  >
                                    Retake Quiz
                                  </Button>
                                )}
                                {!alHasQuiz && (
                                  <Button
                                    variant="info"
                                    onClick={() => {
                                      takeQuiz({
                                        lesson: activeLesson,
                                        userDoc,
                                      });
                                    }}
                                  >
                                    Take quiz
                                  </Button>
                                )}
                              </div>
                            </React.Fragment>
                          )}
                          {user && !activeLesson && (
                            <p className="lead">
                              Select a lesson on the left to continue.
                            </p>
                          )}
                        </Col>
                        {user && activeLesson && (
                          <Col xs={12} className="mt-5">
                            <div className="p-3 text-left bg-white border-gold box-shadow">
                              <h5>Transcript</h5>
                              <MDEditor.Markdown
                                source={activeLesson.data.transcript}
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </section>
            </main>
          </Col>
        )}
      </Row>
    </Container>
  );
};

const retakeQuiz = (params, callback) => {
  try {
    const { quiz } = params;
    let newQuestions = quiz.questions.map((q) => {
      if (q.selectedAnswer);
      delete q.selectedAnswer;
      return q;
    });
    console.log("newQuestions: ", newQuestions);
    quiz._docRef.update({ graded: false, questions: newQuestions });
    let nq = { ...quiz };
    nq.questions = newQuestions;
    if (callback) {
      callback(null, nq);
    }
    return nq;
  } catch (err) {
    console.log("Error retaking quiz: ", err);
    if (callback) {
      callback(err);
    }
  }
};

const Quizzes = (props) => {
  const { userDoc, handleState } = props;
  useEffect(() => {
    if (handleState) {
      handleState({ isApp: true, noDistract: true });
    }
  }, [handleState]);

  return (
    <Collection
      colRef={db}
      collection="quizzes"
      permissions={["read", "write", "delete"]}
      where={[
        { field: "archived", operand: "!=", term: true },
        { field: "aid", operand: "==", term: userDoc.id },
      ]}
      orderBy={[{ field: "archived" }, { field: "_created" }]}
    >
      <CollectionContext.Consumer>
        {(context) => {
          const { docs, updateDoc, displayDate } = context;
          return (
            <TrelloBoardList
              {...props}
              {...context}
              title={<h3>Quizzes</h3>}
              cardPath="/quiz"
              boardCollectionRef={db.collection("quizzes")}
              config={{ questions: [] }}
              hideNew
            />
          );
        }}
      </CollectionContext.Consumer>
    </Collection>
  );
};

const QuizRouter = (props) => {
  return (
    <Switch>
      <Route exact path={["/quiz/:quizid"]}>
        <Quiz {...props} />
      </Route>
      <Route exact path={["/quiz", "/quiz/"]}>
        <Redirect to="/quizzes" />
      </Route>
    </Switch>
  );
};

const letterMap = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
  11: "L",
  12: "M",
  13: "N",
  14: "O",
  15: "P",
  16: "Q",
  17: "R",
  18: "S",
  19: "T",
  20: "U",
  21: "V",
  22: "W",
  23: "X",
  24: "Y",
  25: "Z",
};

const Quiz = (props) => {
  console.log("Quiz props: ", props);
  const { handleState, backTo } = props;
  const { quizid } = useParams();
  const [quiz, setQuiz] = useState({ quizzes: [] });
  const [lesson, setLesson] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const { questions } = quiz;

  useEffect(() => {
    if (quizid) {
      db.collection("quizzes")
        .doc(quizid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            let docData = doc.data();
            docData._docRef = doc.ref;
            docData._docId = doc.id;
            console.log("docData: ", docData);
            setQuiz(docData);
            if (docData.lessonId) {
              getDoc(
                {
                  ref: db.collection("lessons").doc(docData.lessonId),
                },
                (error, lesson) => {
                  if (error) {
                    // do nothing
                  }
                  if (lesson) {
                    setLesson(lesson);
                  }
                }
              );
            }
          }
        });
    }
    handleState({ isApp: true, noDistract: true });
  }, []);
  //console.log("quiz: ", quiz);
  const q = questions ? questions[selectedQuestion] : null;
  const allQuestionsAnswered = questions
    ? questions
        .map((qu) => typeof qu.selectedAnswer)
        .filter((qu) => qu !== "undefined").length === questions.length
    : false;
  let correct = 0;
  if (questions) {
    questions.map((q) => {
      if (q.answer === q.selectedAnswer) {
        correct += 1;
      }
    });
  }
  let showIntro = lesson ? (lesson.data.instructor ? true : false) : false;
  //console.log("allQuestionsAnswered: ", allQuestionsAnswered);
  return (
    <Container fluid>
      <Row className="bg-light" style={{ minHeight: "100vh" }}>
        {questions && (
          <React.Fragment>
            <Col xs={12} md={4}>
              <Card
                className="mt-5 mb-4 text-center"
                style={{ width: "250px" }}
              >
                <Card.Header>{quiz.name}</Card.Header>
                <Card.Body className="d-flex flex-row justify-content-around flex-wrap">
                  {questions.map((q, i) => {
                    let wrong = quiz.graded
                      ? q.selectedAnswer !== q.answer
                      : false;
                    return (
                      <a
                        style={{ position: "relative" }}
                        key={`qt_${i}`}
                        onClick={() => setSelectedQuestion(i)}
                        className={
                          !quiz.graded
                            ? selectedQuestion === i
                              ? "questionTile border rounded bg-dark"
                              : "questionTile border rounded"
                            : `questionTile border rounded ${
                                !wrong ? "border-success" : "border-danger"
                              }`
                        }
                      >
                        {!quiz.graded && (
                          <span
                            className={
                              q.selectedAnswer
                                ? selectedQuestion === i
                                  ? "text-white"
                                  : "text-dark"
                                : "text-warning"
                            }
                          >
                            {i + 1}
                          </span>
                        )}
                        {quiz.graded && (
                          <span
                            className={`${
                              wrong ? "text-danger" : "text-success"
                            }`}
                          >
                            {i + 1}
                          </span>
                        )}
                        {wrong && (
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                            }}
                          >
                            <Slash
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </div>
                        )}
                      </a>
                    );
                  })}
                </Card.Body>
                <Card.Footer>
                  {!quiz.graded && (
                    <Button
                      block
                      size="sm"
                      onClick={() => {
                        quiz._docRef.update({ graded: true });
                      }}
                    >
                      Finish and Grade
                    </Button>
                  )}
                  {quiz.graded && (
                    <Button
                      block
                      size="sm"
                      onClick={() => {
                        setSelectedQuestion(0);
                        retakeQuiz({ quiz });
                      }}
                    >
                      Retake Quiz
                    </Button>
                  )}
                  <Link
                    className="btn btn-link btn-sm btn-block"
                    to={
                      backTo ? backTo.path : process.env.REACT_APP_redirect_path
                    }
                  >
                    Save and come back later
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs={12} md={8}>
              <div
                className="bg-white shadow p-3 m-2"
                style={{ width: "100%", height: "98%" }}
              >
                {backTo && (
                  <div className="mt-5 mb-4">
                    <Link to={backTo.path}>
                      <ArrowLeft size={16} /> {backTo.text}
                    </Link>
                  </div>
                )}
                {quiz.graded && (
                  <Alert variant="info">
                    <Alert.Heading>
                      You scored {correct} / {questions.length}
                    </Alert.Heading>
                    <p>
                      Click back through the questions to see the correct
                      answers.
                    </p>
                  </Alert>
                )}
                <div className="mt-5 mb-4">
                  <div>
                    <b>{`Q${selectedQuestion + 1}`}</b>/{questions.length}
                  </div>
                  <hr />
                  {showIntro && (
                    <p>
                      <i>According to {lesson.data.instructor},</i>
                    </p>
                  )}
                  <MDEditor.Markdown source={q.question} />
                </div>
                <ListGroup>
                  {q.options.map((o, i) => {
                    return (
                      <ListGroup.Item
                        key={`question_${i}`}
                        action
                        variant={
                          showAnswer
                            ? o === q.answer
                              ? "success"
                              : "danger"
                            : ""
                        }
                        onClick={() => {
                          if (quiz.graded) {
                            return;
                          }
                          let newQuestions = [...quiz.questions];
                          newQuestions[selectedQuestion].selectedAnswer = o;
                          console.log("newQuestions: ", newQuestions);
                          quiz._docRef.update({
                            questions: newQuestions,
                          });
                        }}
                        className={
                          !showAnswer
                            ? q.selectedAnswer === o
                              ? "bg-light border-gold"
                              : ""
                            : ""
                        }
                      >
                        <div className="quizOpt bg-light">{letterMap[i]}</div>{" "}
                        {o}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
                {quiz.graded && (
                  <Card className="bg-light mt-3">
                    <Card.Body>
                      Correct answer: <b>{q.answer}</b>
                      {q.explanation && (
                        <React.Fragment>
                          Explanation
                          <hr />
                          <MDEditor.Markdown source={q.explanation} />
                        </React.Fragment>
                      )}
                    </Card.Body>
                  </Card>
                )}
                <div className="mt-5 d-flex justify-content-between align-items-center">
                  <Button
                    onClick={() => {
                      setSelectedQuestion(selectedQuestion - 1);
                    }}
                    disabled={selectedQuestion === 0}
                  >
                    Previous
                  </Button>
                  <ButtonGroup>
                    {/*<Button
                        variant="outline-secondary"
                        onClick={() => {
                          setShowAnswer(!showAnswer);
                        }}
                      >
                        {showAnswer ? "Hide Answer" : "Reveal Answer"}
                      </Button>*/}
                    <Button
                      onClick={() => {
                        setSelectedQuestion(selectedQuestion + 1);
                      }}
                      disabled={selectedQuestion + 1 === questions.length}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </div>
                {allQuestionsAnswered && (
                  <div className="d-flex flex-row justify-content-end mt-3">
                    {!quiz.graded && (
                      <Button
                        variant="success"
                        onClick={() => {
                          quiz._docRef.update({ graded: true });
                        }}
                      >
                        Finish and Grade
                      </Button>
                    )}
                    {quiz.graded && (
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setSelectedQuestion(0);
                          retakeQuiz({ quiz });
                        }}
                      >
                        Retake Quiz
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </React.Fragment>
        )}
        {!questions && (
          <Col>
            <p>There are no questions in this quiz.</p>
          </Col>
        )}
      </Row>
    </Container>
  );
};

const UserQuizzes = (props) => {
  const { userDoc } = props;

  return (
    <Collection
      colRef={db}
      collection="quizzes"
      permissions={["read", "write", "delete"]}
      where={[
        { field: "archived", operand: "!=", term: true },
        { field: "aid", operand: "==", term: userDoc.id },
      ]}
      orderBy={[{ field: "archived" }, { field: "_created" }]}
    >
      <CollectionContext.Consumer>
        {(context) => {
          const { docs, updateDoc, displayDate } = context;

          return (
            <React.Fragment>
              <Table
                className="p-3 mb-4"
                size="lg"
                bordered
                style={{ fontSize: "1.5rem" }}
              >
                <tbody>
                  <tr>
                    <td colSpan="2">
                      {userDoc.userProfile.name}{" "}
                      <Badge variant="info">{userDoc.userProfile.org}</Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="font-bold">Total Quizzes Started: </span>
                    </td>
                    <td>{docs.length}</td>
                  </tr>
                </tbody>
              </Table>
              <h5>Quizzes</h5>
              <Table
                size="sm"
                striped
                hover
                bordered
                responsive
                className="text-center"
              >
                <thead>
                  <tr>
                    <th className="text-left">Quiz name</th>
                    <th>Started</th>
                    <th>Last activity</th>
                    <th>Progess</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {docs
                    .sort((a, b) => {
                      return a.created - b.created;
                    })
                    .map((d) => {
                      const { _docId, _created, name, lastUpdated, questions } =
                        d;
                      let completed = 0;
                      let correct = 0;
                      questions.forEach((q) => {
                        if (q.selectedAnswer) {
                          completed += 1;
                          if (q.selectedAnswer === q.answer) {
                            correct += 1;
                          }
                        }
                      });
                      return (
                        <tr key={_docId}>
                          <td className="text-left">{name ? name : ""}</td>
                          <td>
                            {_created
                              ? new Date(_created * 1000).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )
                              : "-"}
                          </td>
                          <td>
                            {lastUpdated
                              ? new Date(lastUpdated * 1000).toLocaleString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )
                              : "-"}
                          </td>
                          <td>
                            {Math.round((completed / questions.length) * 100)}%
                          </td>
                          <td>
                            {correct} / {questions.length}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </React.Fragment>
          );
        }}
      </CollectionContext.Consumer>
    </Collection>
  );
};

const OrgAdmin = (props) => {
  const { userDoc, superAdmin } = props;
  const [showModal, setShowModal] = useState(false);
  const [userProgress, setUserProgress] = useState(null);

  if (!userDoc.orgAdmin && !superAdmin) {
    return <NotFound />;
  }
  return (
    <React.Fragment>
      <Collection
        colRef={db}
        collection="users"
        permissions={["read", "write", "delete"]}
        where={
          superAdmin
            ? null
            : [
                {
                  field: "userProfile.org",
                  operand: "in",
                  term: userDoc.orgAdmin,
                },
              ]
        }
        orderBy={[{ field: "userProfile.name" }]}
      >
        <CollectionContext.Consumer>
          {(context) => {
            const { docs, updateDoc, displayDate } = context;
            return (
              <React.Fragment>
                <h4 className="m-4">
                  Viewing users for{" "}
                  {userDoc.orgAdmin
                    ? userDoc.orgAdmin.join(", ")
                    : "all organizations"}
                </h4>
                <Table striped hover responsive size="sm" className="p-2">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Account created</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docs.map((d) => {
                      const { _docId, _docCreated, userProfile, orgAdmin } = d;
                      const { name, email, phone, org } = userProfile;
                      return (
                        <tr key={_docId}>
                          <td>
                            {name ? (
                              <>
                                <span>{name}</span>{" "}
                                <Badge className="ml-2" variant="light">
                                  {org}
                                </Badge>
                              </>
                            ) : (
                              "-"
                            )}
                            {orgAdmin ? (
                              <span className="text-muted ml-2">(admin)</span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>{email ? email : d.email ? d.email : "-"}</td>
                          <td>{phone ? formatPhoneNumber(phone) : "-"}</td>
                          <td>
                            {_docCreated
                              ? new Date(
                                  _docCreated.seconds * 1000
                                ).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })
                              : "-"}
                          </td>
                          <td>
                            <Button
                              variant="outline-info"
                              size="sm"
                              onClick={() => {
                                setUserProgress(d);
                                setShowModal(true);
                              }}
                            >
                              View Progress
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {showModal && (
                  <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    size="xl"
                  >
                    <Modal.Header>
                      <Modal.Title>View Progress</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <UserQuizzes userDoc={userProgress} />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="light"
                        size="sm"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}
              </React.Fragment>
            );
          }}
        </CollectionContext.Consumer>
      </Collection>
    </React.Fragment>
  );
};

export {
  TopNav,
  FofBrand,
  UserNav,
  SideBar,
  Home,
  Footer,
  FooterSmall,
  LessonRouter,
  Lesson,
  Courses,
  settingsPanes,
  Quizzes,
  QuizRouter,
  GetInvolved,
  AboutUs,
  ReadingList,
  OrgAdmin,
  ShareLessons,
  RecommendLecturer,
  HowItWorks,
};
