import React, {useState, useEffect} from "react";
import {Redirect} from "react-router-dom";
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import {formatPhoneNumber} from "./interfaceListShackPro.jsx";

const UserProfile = props => {
  //console.log("UserProfile props: ", props);
  let { userDoc, handleAlerts, apiKey, size, user, isCreate } = props;
  let blankProfile = { name: "", phone: "", email: user.email, photoUrl: "", org: "" };
  let eUserProfile =
    userDoc.userProfile !== undefined
      ? userDoc.userProfile
      : blankProfile;
  const [userProfile, setUserProfile] = useState(eUserProfile);
  const [otherOrg, setOtherOrg] = useState(false);
  const [sentVerification, setSentVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verifiedTrue, setVerifiedTrue] = useState(false);
  const verified = userDoc.verified;
  //console.log("verified: ", verified, "otherOrg: ", otherOrg);

  useEffect( () => {
    if ( !isCreate ) {
      if ( !props.orgs.includes(userDoc.userProfile.org) ) {
        setOtherOrg(true);
      }
    }
  }, []);

  if (verified && isCreate) {
    return(
      <Redirect to={process.env.REACT_APP_redirect_path} />
    )
  }
  const updateUserProfile = async (profileObject) => {
    if (profileObject.name === "") {
      handleAlerts("", "Please update the name.", "warning");
      return false;
    }
    if (profileObject.phone === "") {
      handleAlerts("", "Please update the phone number.", "warning");
      return false;
    }
    if (profileObject.email === "") {
      handleAlerts("", "Please update the email.", "warning");
      return false;
    }
    if (["", "other"].includes(profileObject.org) && props.requireUserOrg) {
      handleAlerts("", "Please select an organization.", "warning");
      return false;
    }

    try {
      await userDoc._docRef.update({
        userProfile: profileObject
      });
      // update the firebase user object
      handleAlerts("", `Your profile has been ${isCreate ? "created" : "updated"}!`, "success");
      return true;
    } catch (err) {
      console.log("Error updating company profile: ", err);
    }
  };

  const disableProfileUpdate =
    (userDoc.userProfile === undefined &&
      JSON.stringify(userProfile) === JSON.stringify(blankProfile)) ||
    (userDoc.userProfile !== undefined &&
      JSON.stringify(userDoc.userProfile) ===
        JSON.stringify(userProfile));

  return (
    <Container fluid className="p-0">
      <Row noGutters={true}>
        {!sentVerification && (
          <Col xs={12}>
            {props.heading ? props.heading : null}
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                size={size ? size : "lg"}
                type="text"
                placeholder="Enter name"
                value={userProfile.name}
                onChange={e =>
                  setUserProfile({
                    email: userProfile.email,
                    name: e.target.value,
                    phone: userProfile.phone,
                    photoUrl: userProfile.photoUrl,
                    org: userProfile.org
                  })
                }
              />
              {props.nameText &&
                <Form.Text className="text-muted">
                  {props.nameText}
                </Form.Text>
              }
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                size={size ? size : "lg"}
                type="text"
                placeholder="Enter cell phone"
                value={userProfile.phone}
                onChange={e =>
                  setUserProfile({
                    email: userProfile.email,
                    name: userProfile.name,
                    phone: e.target.value,
                    photoUrl: userProfile.photoUrl,
                    org: userProfile.org
                  })
                }
              />
              {props.phoneText &&
                <Form.Text className="text-muted">
                  {props.phoneText}
                </Form.Text>
              }
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                size={size ? size : "lg"}
                type="text"
                placeholder="Enter email"
                value={userProfile.email}
                disabled={true}
                onChange={e =>
                  setUserProfile({
                    email: e.target.value,
                    name: userProfile.name,
                    phone: userProfile.phone,
                    photoUrl: userProfile.photoUrl,
                    org: userProfile.org
                  })
                }
              />
              {props.emailText &&
                <Form.Text className="text-muted">
                  {props.emailText}
                </Form.Text>
              }
            </Form.Group>
            {props.addInfo.includes("org") &&
              <React.Fragment>
                <Form.Group>
                  <Form.Label>Select organization</Form.Label>
                  <Form.Control
                    size={size ? size : "lg"}
                    as="select"
                    value={ otherOrg ? props.orgs.includes(userProfile.org) ? userProfile.org : "other" : userProfile.org}
                    onChange={e => {
                      if (e.target.value === "other") {
                        setOtherOrg(true);
                        setUserProfile({
                          email: userProfile.email,
                          name: userProfile.name,
                          phone: userProfile.phone,
                          photoUrl: userProfile.photoUrl,
                          org: ""
                        })
                      } else {
                        setOtherOrg(false);
                        setUserProfile({
                          email: userProfile.email,
                          name: userProfile.name,
                          phone: userProfile.phone,
                          photoUrl: userProfile.photoUrl,
                          org: e.target.value
                        })
                      }
                    }}
                  >
                    <option>Select organization</option>
                    {props.orgs.map( org => {
                      return(
                        <option key={org} value={org}>{org}</option>
                      )
                    })}
                    <option value="other">Other</option>
                  </Form.Control>
                  {props.orgText &&
                    <Form.Text className="text-muted">
                      {props.orgText}
                    </Form.Text>
                  }
                </Form.Group>
                {otherOrg &&
                  <Form.Group>
                    <Form.Label>Organization Name</Form.Label>
                    <Form.Control
                      size={size ? size : "lg"}
                      type="text"
                      value={userProfile.org}
                      onChange={e => {
                        let nup = {...userProfile};
                        nup.org = e.target.value;
                        setUserProfile(nup);
                      }}
                    />
                    <Form.Text>Please add the name of your organization.</Form.Text>
                  </Form.Group>
                }
              </React.Fragment>
            }

            {!verified && (
              <Button
                onClick={async e => {
                  const init = {
                    method: "POST",
                    headers: {
                      authorization: `Bearer ${apiKey}`,
                      "Content-Type": "application/json"
                    },
                    "Transfer-Encoding": "chunked",
                    cache: "default",
                    accept: "application/json",
                    body: JSON.stringify({
                      sms_to: userProfile.phone,
                      email_to: userProfile.email,
                      email_from: process.env.REACT_APP_contact_email,
                      siteName: process.env.REACT_APP_site_name,
                      db: process.env.REACT_APP_firebase_projectId
                    })
                  };
                  let url = `${process.env.REACT_APP_api_url}/api/start_verification`;
                  console.log("url: ", url, "init: ", init);

                  try {
                    let updatedProfile = await updateUserProfile(userProfile);
                    if (updatedProfile) {
                      await fetch(url, init);
                      setSentVerification(true);
                    }
                  } catch (err) {
                    console.log(
                      "Something went wrong verifying the company: ",
                      err
                    );
                    handleAlerts(
                      "",
                      `Uh oh, we couldn't verify your account: ${err.message}`,
                      "warning"
                    );
                  }
                }}
                disabled={disableProfileUpdate}
                size="lg"
                className="pull-right"
              >
                Verify account profile
              </Button>
            )}
          </Col>
        )}
        {sentVerification && (
          <Col xs={12}>
            <p className="lead mt-3">
              Sent verification code to:
              <br />
              {userProfile.email}
              <br />
              {/* Text messages with Twilio are not working
              <Button
                variant="link"
                size="sm"
                className="mb-4 d-flex justify-content-center align-items-center"
                onClick={() => setSentVerification(false)}
              >
                Click here to change U.S. cell phone number
              </Button>
              */}
            </p>
            {!verifiedTrue && (
              <React.Fragment>
                <Form.Group>
                  <Form.Label>Enter verification code</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="123456"
                    value={verificationCode}
                    onChange={e => setVerificationCode(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    Enter the verification code sent to your phone number.
                  </Form.Text>
                </Form.Group>
                <Button
                  onClick={async e => {
                    e.preventDefault();
                    const init = {
                      method: "POST",
                      headers: {
                        authorization: `Bearer ${apiKey}`,
                        "Content-Type": "application/json"
                      },
                      "Transfer-Encoding": "chunked",
                      cache: "default",
                      accept: "application/json",
                      body: JSON.stringify({
                        verificationCode,
                        verifyFirebaseUser: true,
                        userProfile,
                        db: process.env.REACT_APP_firebase_projectId
                      })
                    };
                    let url = `${process.env.REACT_APP_api_url}/api/verify_account`;
                    console.log("url: ", url, "init: ", init);
                    let response = await fetch(url, init);
                    let verification = await response.json();
                    console.log("verification: ", verification);
                    if (verification.verified) {
                      setVerifiedTrue(true);
                      handleAlerts("", "Huzzah! You successfully verified your account.", "success");
                    }
                    if (!verification.verified && verification.expired) {
                      handleAlerts(
                        "",
                        "Your verfication code has expired.  Please send a new one.",
                        "warning"
                      );
                    }
                    if (!verification.verified && !verification.expired) {
                      handleAlerts(
                        "",
                        "Your verfication code is incorrect.  Please try again.",
                        "warning"
                      );
                    }
                  }}
                >
                  Verify Account
                </Button>
                <Button
                  variant="link"
                  onClick={e => {
                    e.preventDefault();
                    const init = {
                      method: "POST",
                      headers: {
                        authorization: `Bearer ${apiKey}`,
                        "Content-Type": "application/json"
                      },
                      "Transfer-Encoding": "chunked",
                      cache: "default",
                      accept: "application/json",
                      body: JSON.stringify({
                        sms_to: userProfile.phone,
                        email_to: userProfile.email
                      })
                    };
                    let url = `${process.env.REACT_APP_api_url}/api/start_verification`;
                    console.log("url: ", url, "init: ", init);
                    fetch(url, init);
                  }}
                >
                  Resend code
                </Button>
              </React.Fragment>
            )}
            {verifiedTrue && (
              <h4 className="text-success">
                <i className="fa fa-check" aria-hidden="true"></i>Account
                verified
              </h4>
            )}
          </Col>
        )}

        <Col xs={12} style={{ paddingBottom: "100px" }}>
          {verified && !verifiedTrue && (
            <Button
              onClick={() => updateUserProfile(userProfile)}
              disabled={disableProfileUpdate}
              size="lg"
              className="pull-right"
            >
              Update profile
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export {UserProfile};
