import React from "react";
import storeMedia from "./storeMedia.jsx";
import {
  Container,
  Row,
  Col,
  CardColumns,
  Card,
  Form,
  Button,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Modal,
  Image
} from "react-bootstrap";
import { NotFound } from "./interfaceListShackPro.jsx";
import { db } from "./firebase.jsx";

const CollectionContext = React.createContext([]);
//const DocumentContext = React.createContext({});
const _collection = "sites";
const _siteId = "PTqTnlBFCxQF6jjXLgDn";

const getDocs = async collection => {
  const collectionRef = db
    .collection(_collection)
    .doc(_siteId)
    .collection(collection);

  let docs = [];
  await collectionRef.get().then(snapShot => {
    snapShot.forEach(doc => {
      let fbDoc = doc.data();
      fbDoc["_docId"] = doc.id;
      fbDoc["_docPath"] = doc.ref.path;
      docs.push(fbDoc);
    });
  });
  return docs;
};

const checkNull = x => {
  if (x === null) return "";
  return x;
};

const checkUndefined = x => {
  if (x === undefined) return "";
  return x;
};

const Field = props => {
  //console.log("field props: ", props);
  let {
    field,
    fieldValue,
    label,
    updateDoc,
    doc,
    fieldsMap,
    size,
    canWrite
  } = props;
  //console.log(typeof fieldValue);

  const deleteField = deletField => {
    //console.log("deleteField: ", deletField);
    let newDoc = JSON.parse(JSON.stringify(doc));
    delete newDoc[deleteField];
    updateDoc(newDoc);
  };

  const addField = (newField, newValue) => {
    let newDoc = JSON.parse(JSON.stringify(doc));
    newDoc[newField] = newValue;
    updateDoc(newDoc);
  };

  let as = fieldsMap
    ? fieldsMap[field] === "textarea" || fieldsMap[field] === "select"
      ? fieldsMap[field]
      : "input"
    : "input";
  // Where it's a metadata field starting with underscore "_"
  if (field[0] === "_") {
    return (
      <Form.Group>
        <Form.Label column={size}>{label}</Form.Label>
        <Form.Control
          size={size}
          plaintext={true}
          readOnly={true}
          name={field}
          type={fieldsMap ? fieldsMap[field] : "text"}
          as={as}
          defaultValue={
            fieldsMap[field] === "timestamp"
              ? new Date(doc[field] * 1000).toLocaleString()
              : checkNull(doc[field])
          }
        />
      </Form.Group>
    );
  }

  // Where the field is a string or a number
  if (typeof fieldValue === "string" || typeof fieldValue === "number") {
    return (
      <Form.Group>
        <Form.Label column={size}>{label}</Form.Label>
        <Form.Control
          size={size}
          name={field}
          readOnly={canWrite ? false : true}
          type={fieldsMap ? fieldsMap[field] : "text"}
          as={as}
          value={checkNull(fieldValue)}
          onChange={e => {
            e.preventDefault();
            let newDoc = { ...doc };
            if (typeof fieldValue === "string") {
              newDoc[field] = e.target.value;
            }
            if (typeof fieldValue === "number") {
              newDoc[field] = parseFloat(e.target.value);
            }
            //console.log("doc: ", doc, "newDoc: ", newDoc);
            updateDoc(newDoc);
          }}
        />
      </Form.Group>
    );
  }
  // Where the field is a boolean
  if (typeof fieldValue === "boolean") {
    return (
      <Form.Group>
        <Form.Check
          type="checkbox"
          label={label}
          disabled={canWrite ? false : true}
          checked={fieldValue}
          onClick={e => {
            e.preventDefault();
            let newDoc = { ...doc };
            newDoc[field] = !fieldValue;
            //console.log("doc: ", doc, "newDoc: ", newDoc);
            updateDoc(newDoc);
          }}
        />
      </Form.Group>
    );
  }

  // Where the field is an array
  if (typeof fieldValue === "object" && Array.isArray(fieldValue)) {
    return (
      <Form.Group key={`form_${field}`}>
        <Form.Label column={size}>{label}</Form.Label>
        {fieldValue.map((arrayValue, i) => {
          return (
            <Form.Control
              size={size}
              key={`fc_${field}_${i}`}
              readOnly={canWrite ? false : true}
              type={fieldsMap ? fieldsMap[field] : "text"}
              as={as}
              placeholder={`${field} ${i}`}
              value={fieldValue[i]}
              onChange={e => {
                e.preventDefault();
                let newDoc = JSON.parse(JSON.stringify(doc));
                newDoc[field][i] = e.target.value;
                //console.log("newDoc: ", newDoc);
                updateDoc(newDoc);
              }}
            />
          );
        })}
      </Form.Group>
    );
  }
  // where the field is an object
  if (typeof fieldValue === "object") {
    let subfields = Object.keys(fieldValue).sort();
    //console.log("subfields: ", subfields);
    //console.log("field props: ", props);
    //console.log(typeof fieldValue);
    return (
      <Form.Group key={`form_${field}`}>
        <Form.Label column={size}>{label}</Form.Label>
        {subfields.map((subfield, i) => {
          let hasSubfieldMap = fieldsMap
            ? fieldsMap[field]
              ? fieldsMap[field][subfield]
                ? true
                : false
              : false
            : false;
          let subas = !hasSubfieldMap ? "input" : fieldsMap[field][subfield];
          return (
            <Form.Control
              size={size}
              key={`fc_${subfield}_${i}`}
              name={subfield}
              readOnly={canWrite ? false : true}
              type={hasSubfieldMap ? fieldsMap[field][subfield] : "text"}
              as={subas}
              placeholder={subfield}
              value={checkNull(fieldValue[subfield])}
              onChange={e => {
                e.preventDefault();
                let newDoc = JSON.parse(JSON.stringify(doc));
                newDoc[field][subfield] = e.target.value;
                //console.log("newDoc: ", newDoc);
                updateDoc(newDoc);
              }}
            />
          );
        })}
      </Form.Group>
    );
  }
  return null;
};

class NewDoc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Add variable from Collection docConfig dynamically
    };
    for (let field of Object.keys(this.props.context.docConfig)) {
      //console.log("field: ", field);
      this.state[field] = this.props.context.docConfig[field]["value"];
    }
  }

  createNewDoc() {
    let { context, user, approveDoc, callBack } = this.props;
    let newDoc = {};
    let blankDoc = {};
    try {
      for (let field of Object.keys(context.docConfig)) {
        if (context.docConfig[field]["required"] && this.state[field] === "") {
          return window.alert(
            `Please add a ${context.docConfig[field]["label"]}.`
          );
        }
        //console.log("field: ", field);
        if (field === "metaData") {
          let metaData = context.docConfig.metaData;
          Object.keys(context.docConfig.metaData).map(metafield => {
            return (newDoc[metafield] = metaData[metafield]);
          });
        } else {
          newDoc[field] = this.state[field];
          blankDoc[field] = "";
        }
      }
      newDoc["user"] = user;
      newDoc["_created"] = Math.round(new Date().getTime() / 1000);
      newDoc["_lastChanged"] = Math.round(new Date().getTime() / 1000);
      newDoc["approved"] = approveDoc;
      // Add the document to the collection in firebase
      //console.log( "newDoc: ", newDoc );
      context.addDoc(newDoc);
      // Reset state for another entry
      blankDoc["file"] = "";
      //console.log( "blankDoc: ", blankDoc);
      this.setState(blankDoc);
      if (callBack) {
        callBack(undefined, newDoc);
      }
    } catch (err) {
      console.log("Error creating new doc: ", err);
      if (callBack) {
        callBack(err);
      }
    }
  }

  render() {
    //console.log("props: ", this.props, "this.state: ", this.state);
    let { context, handleState } = this.props;
    const doc = context.docConfig;
    let formGroups = [];
    //console.log(Object.keys(doc));
    for (let [i, formField] of Object.keys(doc).entries()) {
      //console.log("formField: ", formField);
      let type = doc[formField]["type"];
      let label = doc[formField]["label"];
      let size = doc[formField]["size"];
      let fieldClass = doc[formField]["class"];
      //console.log("value: ", value);
      let rows = doc[formField]["rows"] ? doc[formField]["rows"] : undefined;
      if (type === "text" || type === "number") {
        formGroups.push(
          <Form.Group key={`fg_${i}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
              size={size}
              type={type}
              value={this.state[formField]}
              onChange={e => {
                //console.log(e.target.value);
                this.setState({
                  [formField]: e.target.value
                });
              }}
              className={fieldClass}
            />
          </Form.Group>
        );
      }
      if (type === "textarea") {
        formGroups.push(
          <Form.Group key={`fg_${i}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
              size={size}
              type={type}
              as={type}
              rows={rows}
              value={this.state[formField]}
              onChange={e => {
                this.setState({
                  [formField]: e.target.value
                });
              }}
              className={fieldClass}
            />
          </Form.Group>
        );
      }
      if (type === "file") {
        let imagePreview = [];
        if (this.state[formField] !== "") {
          imagePreview.push(
            <React.Fragment>
              <Image src={this.state[formField]} fluid />
              <Form.Text className="mb-3">{this.state.file.name}</Form.Text>
            </React.Fragment>
          );
        }

        formGroups.push(
          <Form.Group key={`fg_${i}`}>
            <Form.Label>{doc[formField]["label"]}</Form.Label>
            {imagePreview}
            <Form.File
              id="custom-file-translate-scss"
              label={"Select image"}
              lang="en"
              custom
              value={this.state.fileName}
              onChange={async e => {
                let file = e.target.files[0];
                console.log("file: ", file);
                let random =
                  Math.random()
                    .toString(36)
                    .substring(2, 15) +
                  Math.random()
                    .toString(36)
                    .substring(2, 15);
                console.log("random: ", random);
                await storeMedia(
                  `ttnb2my5ca9mjz40jtfbwq/${random}`,
                  file,
                  p => console.log(p),
                  link => {
                    this.setState({
                      [formField]: link,
                      file: file
                    });
                  },
                  m => console.log(m)
                );
              }}
            />
          </Form.Group>
        );
      }

      if (type === "boolean") {
        formGroups.push(
          <Form.Group key={`fg_${i}`}>
            <Form.Check type="checkbox" label={doc[formField].label} />
          </Form.Group>
        );
      }
    }
    if (this.props.modal) {
      return (
        <React.Fragment>
          <Modal.Header>Request a new feature</Modal.Header>
          <Modal.Body>
            <Form>{formGroups}</Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                this.createNewDoc();
                // Close the modal
                handleState({ showModal: false });
              }}
            >
              {this.props.buttonText}
            </Button>
          </Modal.Footer>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Form className={this.props.formClass}>{formGroups}</Form>
          <Button
            onClick={() => {
              this.createNewDoc();
            }}
          >
            {this.props.buttonText}
          </Button>
        </React.Fragment>
      );
    }
  }
}

class Doc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: {}
    };
    this.updateDoc = this.updateDoc.bind(this);
  }
  saveDoc() {
    try {
      let newDoc = { ...this.state.doc };
      newDoc["_lastChanged"] = Math.round(new Date().getTime() / 1000);
      db.collection(_collection)
        .doc(_siteId)
        .collection(this.props.collection)
        .doc(this.state.doc._docId)
        .update(newDoc);
      console.log("Saved document to firestore: ", this.state.doc);
    } catch (err) {
      console.log("err saving firebase document: ", err);
    }
  }

  duplicateDoc() {
    let newDoc = { ...this.state.doc };
    delete newDoc["_docId"];
    try {
      db.collection(_collection)
        .doc(_siteId)
        .collection(this.props.collection)
        .add(this.state.doc);
      console.log("Duplicated document to firestore: ", this.state.doc);
    } catch (err) {
      console.log("err duplicating firebase document: ", err);
    }
  }

  deleteDoc() {
    if (window.confirm("Are you sure you'd like to delete this document?")) {
      try {
        db.collection(_collection)
          .doc(_siteId)
          .collection(this.props.collection)
          .doc(this.props.doc._docId)
          .delete();
        console.log("Deleted document from firestore: ", this.state.doc);
      } catch (err) {
        console.log("err deleting firebase document: ", err);
      }
    }
  }

  updateDoc(newDoc) {
    console.log("updateDoc newDoc: ", newDoc);
    this.setState({
      doc: newDoc
    });
  }

  render() {
    //console.log("field this.context: ", this.context);
    //console.log("Doc this.state: ", this.state);
    //console.log(this.state.copyDoc === JSON.stringify(this.state.doc));
    let { canRead, canWrite, canDelete, size } = this.props;

    if (!canRead) return <NotFound />;
    let hasChanged = JSON.stringify(this.state.doc) !== this.state.copyDoc;
    //console.log("hasChanged: ", hasChanged, JSON.stringify(this.state.doc), JSON.stringify(this.state.copyDoc));
    let hasHeader = this.props.header ? true : false;
    //console.log("hasHeader: ", hasHeader);

    return (
      <Card
        style={this.props.cardStyle ? this.props.cardStyle : { width: "18rem" }}
      >
        {hasHeader && (
          <Card.Header as="h5">{this.state.doc[this.props.header]}</Card.Header>
        )}
        <Card.Body>
          <Form>
            {Object.keys(this.state.doc)
              .sort()
              .map((field, i) => {
                return (
                  <Field
                    key={`form_${field}`}
                    size={size}
                    field={field}
                    label={field}
                    fieldValue={this.state.doc[field]}
                    updateDoc={this.updateDoc}
                    canRead={canRead}
                    canWrite={canWrite}
                    canDelete={canDelete}
                    {...this.props}
                  />
                );
              })}
          </Form>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between">
          <ButtonGroup>
            <Button
              onClick={e => {
                this.saveDoc();
              }}
              disabled={!canWrite || !hasChanged}
            >
              Save
            </Button>
            <DropdownButton as={ButtonGroup} title="" id="bg-nested-dropdown">
              <Dropdown.Item
                onClick={e => {
                  this.duplicateDoc();
                }}
                disabled={!canWrite}
              >
                Duplicate
              </Dropdown.Item>
              <Dropdown.Item
                onClick={e => {
                  this.deleteDoc();
                }}
                disabled={!canDelete}
              >
                Delete
              </Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
        </Card.Footer>
      </Card>
    );
  }

  componentDidUpdate() {
    //console.log(this.props.doc, JSON.parse(this.state.copyDoc));
    if (JSON.stringify(this.props.doc) !== this.state.copyDoc) {
      let copyDoc = JSON.stringify({ ...this.props.doc });
      this.setState({
        doc: JSON.parse(copyDoc),
        copyDoc
      });
    }
  }

  componentDidMount() {
    let copyDoc = JSON.stringify({ ...this.props.doc });
    this.setState({
      doc: JSON.parse(copyDoc),
      copyDoc
    });
  }
}

class Collection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snapShot: null,
      firestoreRef: "",
      docs: [],
      doc: {},
      docConfig: this.props.docConfig,
      // Collection props
      props: this.props,
      // Collection methods
      updateDoc: async (newDoc, callback) => {
        console.log("updateDoc");
        try {
          await this.props.colRef
            .collection(this.props.collection)
            .doc(newDoc._docId)
            .update(newDoc);
          if (callback) {
            callback(null, newDoc);
          }
          return newDoc;
        } catch (err) {
          console.log("Couldn't update document: ", err);
          if (callback) {
            callback(err);
          }
          return err;
        }
      },
      deleteDoc: docId => {
        try {
          this.props.colRef
            .collection(this.props.collection)
            .doc(docId)
            .delete();
          console.log("Document deleted: ", docId);
        } catch (err) {
          console.log("Couldn't delete document: ", err);
        }
      },
      addDoc: newDoc => {
        console.log("updateDoc");
        try {
          this.props.colRef.collection(this.props.collection).add(newDoc);
        } catch (err) {
          console.log("Couldn't add document: ", err);
        }
      },
      handleColState: obj => {
        this.setState(obj);
      },
      displayDate: secSinceEpoch => {
        let options = { year: 'numeric', month: 'long', day: 'numeric' }
        return( new Date(secSinceEpoch * 1000).toLocaleString("en-US", options) )
      }
    };
    // set convenience permission variables
    this.state.canRead = this.props.permissions
      ? this.props.permissions.includes("read")
      : false;
    this.state.canWrite = this.props.permissions
      ? this.props.permissions.includes("write")
      : false;
    this.state.canDelete = this.props.permissions
      ? this.props.permissions.includes("delete")
      : false;
  }

  listenDocs = async (params, callback) => {
    let {
      collection,
      where,
      orderBy,
      startAt,
      endAt,
      endBefore,
      startAfter,
      limit,
      listen
    } = params;
    //console.log("collection: ", collection, "where: ", where, "orderBy: ", orderBy, "limit: ", limit);
    if (!listen) { listen=true};
    let collectionRef = this.props.colRef
    if (collection){
      collectionRef = collectionRef.collection(collection);
    }
    if (where) {
      for (let query of where) {
        collectionRef = collectionRef.where(
          query.field,
          query.operand,
          query.term
        );
      }
    }
    if (orderBy) {
      for (let orderItem of orderBy) {
        //let obs = `${orderItem.field}, ${orderItem.order}`;
        //console.log("obs: ", obs);
        if (orderItem.order) {
          collectionRef = collectionRef.orderBy(
            orderItem.field,
            orderItem.order
          );
        } else {
          collectionRef = collectionRef.orderBy(orderItem.field);
        }
      }
    }
    if (startAt) {
      console.log("startAt: ", startAt);
      collectionRef = collectionRef.startAt(startAt);
    }
    if (endAt) {
      //console.log("endAt: ", endAt);
      if (Array.isArray(startAfter)) {
        console.log(endAt[0], endAt[1]);
        collectionRef = collectionRef.startAfter(endAt[0], endAt[1]);
      } else {
        collectionRef = collectionRef.startAfter(startAfter);
      }
    }
    if (endBefore) {
      collectionRef = collectionRef.endBefore(endBefore);
    }
    if (startAfter) {
      //console.log("startAfter: ", startAfter);
      if (Array.isArray(startAfter)) {
        console.log(startAfter[0], startAfter[1]);
        collectionRef = collectionRef.startAfter(startAfter[0], startAfter[1]);
      } else {
        collectionRef = collectionRef.startAfter(startAfter);
      }

    }
    if (limit) {
      collectionRef = collectionRef.limit(limit);
    }
    //console.log("collectionRef: ", collectionRef);
    const handleResults = snapShot => {
      console.log("Collection changed: ", collection);
      let docs = [];
      let colPath;
      snapShot.forEach(doc => {
        //console.log("doc: ", doc);
        let fbDoc = doc.data();
        fbDoc["_docId"] = doc.id;
        fbDoc["_docRef"] = doc.ref;
        if (!colPath) {
          let docPath = `${doc.ref.path}`.split("/");
          docPath.pop();
          colPath = docPath.join("/");
        }
        docs.push(fbDoc);
      });
      this.setState({
        snapShot,
        docs,
        firestoreRef: {
          colPath,
          where,
          orderBy,
          limit
        }
      });
      if(callback) {
        callback(null, docs);
      }
      return docs;
    }
    if (listen) {
      await collectionRef.onSnapshot(handleResults);
    } else {
      await collectionRef.get().then(handleResults);
    }

  };

  render() {
    //console.log("this.state: ", this.state);
    //console.log("Collection this.props: ", this.props);
    let canRead = this.props.permissions
      ? this.props.permissions.includes("read")
      : false;
    //console.log("canRead: ", canRead);
    if (!canRead) return <NotFound />;

    let canWrite = this.props.permissions
      ? this.props.permissions.includes("write")
      : false;
    //console.log("canWrite: ", canWrite);

    let canDelete = this.props.permissions
      ? this.props.permissions.includes("delete")
      : false;
    //console.log("canDelete: ", canDelete);

    let { size } = this.props;

    if (this.props.children) {
      return (
        <CollectionContext.Provider value={this.state}>
          <Container fluid id="viewCollection">
            <Row noGutters>
              <Col className={this.props.className}>
                {this.props.title && <span>{this.props.title}</span>}
                {this.props.children}
              </Col>
            </Row>
          </Container>
        </CollectionContext.Provider>
      );
    } else {
      return (
        <Container>
          <Row noGutters>
            <Col>
              <CollectionContext.Provider value={this.state}>
                {this.props.title && <h1>{this.props.title}</h1>}
                {!this.props.title && (
                  <h1>Collection: {this.props.collection}</h1>
                )}
                <CardColumns>
                  {this.state.docs.sort().map((doc, i) => {
                    return (
                      <Doc
                        size={size}
                        key={`${this.props.collection}_doc_${i}`}
                        collection={this.props.collection}
                        doc={doc}
                        header={this.props.docHeader}
                        permissions={this.props.permissions}
                        canRead={canRead}
                        canWrite={canWrite}
                        canDelete={canDelete}
                        fieldsMap={this.props.fieldsMap}
                      />
                    );
                  })}
                </CardColumns>
              </CollectionContext.Provider>
            </Col>
          </Row>
        </Container>
      );
    }
  }
  componentWillUnmount() {
    //let unsubscribe = this.props.colRef.collection(this.props.collection).onSnapshot( () => {});
    //unsubscribe();
    // unsubscribe from the listener
    const {collection, where, orderBy, startAt, endAt, endBefore, startAfter, limit, callback, listen} = this.props;
    if (listen) {
      this.listenDocs({
        collection,
        where,
        orderBy,
        startAt,
        endAt,
        endBefore,
        startAfter,
        limit,
        listen
      }, callback);
    }
  }
  componentDidUpdate(prevProps) {
    //console.log("Collection didUpdate: ", prevProps, prevProps === this.props);
    if (prevProps !== this.props) {
      const {collection, where, orderBy, startAt, endAt, endBefore, startAfter, limit, callback, listen} = this.props;
      this.listenDocs({
        collection,
        where,
        orderBy,
        startAt,
        endAt,
        endBefore,
        startAfter,
        limit,
        listen
      }, callback);
    }
  }

  async componentDidMount() {
    const {collection, where, orderBy, startAt, endAt, endBefore, startAfter, limit, callback, listen} = this.props;
    this.listenDocs({
      collection,
      where,
      orderBy,
      startAt,
      endAt,
      endBefore,
      startAfter,
      limit,
      listen
    }, callback);
  }
}

export {
  Collection,
  Doc,
  Field,
  NewDoc,
  CollectionContext,
  checkNull,
  checkUndefined
};
